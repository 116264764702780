export default class Patches {
  private duration: number;
  private area: number;
  private centroid_lat: number;
  private centroid_lon: number;
  private id: number;
  private fuel: number;
  private radius: number;
  private patch_tag: string;

  constructor(
    duration: number,
    area: number,
    centroid_lat: number,
    centroid_lon: number,
    id: number,
    fuel: number,
    radius: number,
    patch_tag: string
  ) {
    this.duration = duration;
    this.area = area;
    this.centroid_lat = centroid_lat;
    this.centroid_lon = centroid_lon;
    this.id = id;
    this.fuel = fuel;
    this.radius = radius;
    this.patch_tag = patch_tag;
  }
}
