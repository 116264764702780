import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import {
    tractorRequestSuccess,
    tractorRequestFailure,
} from '../actions/tractorAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'

function* asynctractorRequest(action) {
    try {
        yield put(clearErrors())
        const res = yield call(_api.fetchTripsDates, {
            deviceId: action.payload.tractor.device_pk,
            tractor: action.payload.tractor,
            realTime: action.payload.realTime,
        })
        const dates = Object.keys(res).sort()
        yield put(tractorRequestSuccess({ dates }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))

        yield put(tractorRequestFailure(e))
    }
}

export default function* tractorRequestSaga() {
    yield takeLatest(types.TRACTOR_REQUEST, asynctractorRequest)
}
