export default class Commutes {
  private start: Array<number>;
  private p20: Array<number>;
  private p40: Array<number>;
  private p60: Array<number>;
  private p80: Array<number>;
  private end: Array<number>;
  private id: number;

  constructor(
    start: Array<number>,
    p20: Array<number>,
    p40: Array<number>,
    p60: Array<number>,
    p80: Array<number>,
    end: Array<number>,
    id: number
  ) {
    this.start = start;
    this.p20 = p20;
    this.p40 = p40;
    this.p60 = p60;
    this.p80 = p80;
    this.end = end;
    this.id = id;
  }
}
