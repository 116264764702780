import React, { useEffect, useState } from 'react'
import './App.css'
import Login from './views/Login'
import Dashboard from './views/Dashboard'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { connect } from 'react-redux'
import { loginRequest, loginUpdate } from './store/actions/loginAction'
import { InfiniteLoader } from './elements'
import { AnimatePresence } from 'framer-motion'
import { getGromaxRequest } from './store/actions/getGromaxAction'
import {
    BrowserRouter as Switch,
    Route,
    useLocation,
    useHistory,
} from 'react-router-dom'
import redirect from './utils/redirect'

import Noty from 'noty'
import 'noty/lib/noty.css'
import 'noty/lib/themes/bootstrap-v4.css'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#BE1E2D',
            button: '#FD7E50',
        },
        secondary: {
            main: '#FFFFFF',
        },

        typography: {
            button: {
                textTransform: 'none',
            },
            fontFamily: "'Noto Sans', sans-serif",
        },
    },
})

const App = (props) => {
    const [showTracking, setShowTracking] = useState(false)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const loggedIn = localStorage.getItem('loggedIn') === 'true'
        const phoneNumber = loggedIn ? localStorage.getItem('phoneNumber') : ''
        if (!props.gromaxDevices) {
            props.getGromax()
        }
        if (loggedIn && phoneNumber.length === 10) {
            props.makeLoginRequest({ phone: phoneNumber, otp: '6145' })
        }
        if (loggedIn) {
            if (location.pathname === '/login') {
                history.push('/')
            }
        } else {
            if (location.pathname !== '/login') {
                const queryParams = new URLSearchParams(window.location.search)

                const pin = queryParams.get('pin')
                const pinName = queryParams.get('pin_name')

                window.location.href =
                    '/login?redir=' + redirect(location.pathname, pin, pinName)
            }
        }
    }, [location.pathname, history]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.errorMsg && props.errorMsg !== '') {
            new Noty({
                text: props.errorMsg,
                layout: 'topRight',
                theme: 'bootstrap-v4',
                type: 'error',
                progressBar: true,
                timeout: 1000,
            }).show()
        }
    }, [props.errorMsg])

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                {localStorage.getItem('loggedIn') === 'true' &&
                    props.loadingLogin && <InfiniteLoader />}
                <AnimatePresence transition={{ duration: 0.1 }}>
                    <Switch>
                        <Route exact path="/">
                            {props.isLoggedIn && (
                                <Dashboard
                                    tractors={props.tractors}
                                    realtimeList={props.realTimes}
                                />
                            )}
                        </Route>
                        <Route exact path="/tracking/:id/:date">
                            {props.isLoggedIn && (
                                <Dashboard
                                    tractors={props.tractors}
                                    realtimeList={props.realTimes}
                                    showTracking={showTracking}
                                    setShowTracking={setShowTracking}
                                />
                            )}
                        </Route>
                        <Route exact path="/login">
                            <Login />
                        </Route>
                    </Switch>
                </AnimatePresence>
            </ThemeProvider>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    tractors: state.loginReducer.tractors,
    realTimes: state.loginReducer.realTimes,
    isLoggedIn: state.loginReducer.isLoggedIn,
    loadingLogin: state.loginReducer.loading,
    errorMsg: state.errorReducer.msg,
    gromaxDevices: state.gromaxReducer.data,
})

const mapDispatchToProps = (dispatch) => ({
    getGromax: () => {
        dispatch(getGromaxRequest())
    },
    makeLoginRequest: ({ phone, otp }) => {
        dispatch(loginRequest({ phone, otp }))
    },
    makeLoginUpdate: ({ tractors, id, name, phone, auth_token }) => {
        dispatch(loginUpdate({ tractors, id, name, phone, auth_token }))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
