import Commutes from "./Commutes";
import Patches from "./Patches";

export default class DaySummary {
  private dateId: string; // yyyy-MM-dd <deviceId>
  private date: Date;
  private computationId: number;
  private idling_time: number;
  private job_time: number;
  private haulage_time: number;
  private distance: number;
  private total_fuel: number;
  private total_fuel_lit: number;
  private max_fuel: number;
  private min_fuel: number;
  private area: number;
  private device_fk_id: number;
  private details_flag: number;
  private patches: Patches[];
  private commutes: Commutes[];

  constructor(
    $dateId: string,
    $date: Date,
    $computationId: number,
    $idling_time: number,
    $job_time: number,
    $haulage_time: number,
    $distance: number,
    $total_fuel: number,
    $total_fuel_lit: number,
    $max_fuel: number,
    $min_fuel: number,
    $area: number,
    $device_fk_id: number,
    $details_flag: number,
    $patches: Patches[],
    $commutes: Commutes[]
  ) {
    this.dateId = $dateId;
    this.date = $date;
    this.computationId = $computationId;
    this.idling_time = $idling_time;
    this.job_time = $job_time;
    this.haulage_time = $haulage_time;
    this.distance = $distance;
    this.total_fuel = $total_fuel;
    this.total_fuel_lit = $total_fuel_lit;
    this.max_fuel = $max_fuel;
    this.min_fuel = $min_fuel;
    this.area = $area;
    this.device_fk_id = $device_fk_id;
    this.details_flag = $details_flag;
    this.patches = $patches;
    this.commutes = $commutes;
  }

  /**
   * Getter $dateId
   * @return {string}
   */
  public get $dateId(): string {
    return this.dateId;
  }

  /**
   * Getter $date
   * @return {Date}
   */
  public get $date(): Date {
    return this.date;
  }

  /**
   * Getter $computationId
   * @return {number}
   */
  public get $computationId(): number {
    return this.computationId;
  }

  /**
   * Getter $idling_time
   * @return {number}
   */
  public get $idling_time(): number {
    return this.idling_time;
  }

  /**
   * Getter $job_time
   * @return {number}
   */
  public get $job_time(): number {
    return this.job_time;
  }

  /**
   * Getter $haulage_time
   * @return {number}
   */
  public get $haulage_time(): number {
    return this.haulage_time;
  }

  /**
   * Getter $distance
   * @return {number}
   */
  public get $distance(): number {
    return this.distance;
  }

  /**
   * Getter $total_fuel
   * @return {number}
   */
  public get $total_fuel(): number {
    return this.total_fuel;
  }

  /**
   * Getter $total_fuel_lit
   * @return {number}
   */
  public get $total_fuel_lit(): number {
    return this.total_fuel_lit;
  }

  /**
   * Getter $max_fuel
   * @return {number}
   */
  public get $max_fuel(): number {
    return this.max_fuel;
  }

  /**
   * Getter $min_fuel
   * @return {number}
   */
  public get $min_fuel(): number {
    return this.min_fuel;
  }

  /**
   * Getter $area
   * @return {number}
   */
  public get $area(): number {
    return this.area;
  }

  /**
   * Getter $device_fk_id
   * @return {number}
   */
  public get $device_fk_id(): number {
    return this.device_fk_id;
  }

  /**
   * Getter $details_flag
   * @return {number}
   */
  public get $details_flag(): number {
    return this.details_flag;
  }

  /**
   * Getter $patches
   * @return {Array<Patches>}
   */
  public get $patches(): Array<Patches> {
    return this.patches;
  }

  /**
   * Getter $commutes
   * @return {Array<Commutes>}
   */
  public get $commutes(): Array<Commutes> {
    return this.commutes;
  }

  /**
   * Setter $dateId
   * @param {string} value
   */
  public set $dateId(value: string) {
    this.dateId = value;
  }

  /**
   * Setter $date
   * @param {Date} value
   */
  public set $date(value: Date) {
    this.date = value;
  }

  /**
   * Setter $computationId
   * @param {number} value
   */
  public set $computationId(value: number) {
    this.computationId = value;
  }

  /**
   * Setter $idling_time
   * @param {number} value
   */
  public set $idling_time(value: number) {
    this.idling_time = value;
  }

  /**
   * Setter $job_time
   * @param {number} value
   */
  public set $job_time(value: number) {
    this.job_time = value;
  }

  /**
   * Setter $haulage_time
   * @param {number} value
   */
  public set $haulage_time(value: number) {
    this.haulage_time = value;
  }

  /**
   * Setter $distance
   * @param {number} value
   */
  public set $distance(value: number) {
    this.distance = value;
  }

  /**
   * Setter $total_fuel
   * @param {number} value
   */
  public set $total_fuel(value: number) {
    this.total_fuel = value;
  }

  /**
   * Setter $total_fuel_lit
   * @param {number} value
   */
  public set $total_fuel_lit(value: number) {
    this.total_fuel_lit = value;
  }

  /**
   * Setter $max_fuel
   * @param {number} value
   */
  public set $max_fuel(value: number) {
    this.max_fuel = value;
  }

  /**
   * Setter $min_fuel
   * @param {number} value
   */
  public set $min_fuel(value: number) {
    this.min_fuel = value;
  }

  /**
   * Setter $area
   * @param {number} value
   */
  public set $area(value: number) {
    this.area = value;
  }

  /**
   * Setter $device_fk_id
   * @param {number} value
   */
  public set $device_fk_id(value: number) {
    this.device_fk_id = value;
  }

  /**
   * Setter $details_flag
   * @param {number} value
   */
  public set $details_flag(value: number) {
    this.details_flag = value;
  }

  /**
   * Setter $patches
   * @param {Array<Patches>} value
   */
  public set $patches(value: Array<Patches>) {
    this.patches = value;
  }

  /**
   * Setter $commutes
   * @param {Array<Commutes>} value
   */
  public set $commutes(value: Array<Commutes>) {
    this.commutes = value;
  }
}
