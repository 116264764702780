import * as types from '../types'

export const tractorDetailRequest = ({ requestTractorDetail }) => {
    return {
        type: types.TRACTOR_DETAIL_REQUEST,
        payload: { requestTractorDetail },
    }
}

export const tractorDetailSuccess = ({ tractorDataUpdated }) => {
    return {
        type: types.TRACTOR_DETAIL_SUCCESS,
        payload: {
            tractorDataUpdated,
        },
    }
}

export const tractorDetailFailure = (error) => {
    return {
        type: types.TRACTOR_DETAIL_FAILURE,
        payload: {},
        error: error,
    }
}

export const tractorDetailReset = () => {
    return {
        type: types.TRACTOR_DETAIL_RESET,
    }
}
