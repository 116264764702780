import * as types from '../types'

export const tractorCompanyRequest = ({ requestMakeId }) => {
    return {
        type: types.TRACTOR_COMPANY_REQUEST,
        payload: { requestMakeId },
    }
}

export const tractorCompanySuccess = ({ resTractorCompany }) => {
    return {
        type: types.TRACTOR_COMPANY_SUCCESS,
        payload: {
            resTractorCompany,
        },
    }
}

export const tractorCompanyFailure = (error) => {
    return {
        type: types.TRACTOR_COMPANY_FAILURE,
        payload: {},
        error: error,
    }
}
