// Your web app's Firebase configuration
export const config = {
    apiKey: 'AIzaSyAEXnSZr2mu--JzcXXjGAlbuuf1DOPE-HM',
    authDomain: 'traclytics.firebaseapp.com',
    databaseURL: 'https://traclytics-new.firebaseio.com',
    projectId: 'traclytics',
    storageBucket: 'traclytics.appspot.com',
    messagingSenderId: '470009066526',
    appId: '1:470009066526:web:3be2bd2c1911ec9e736a0f',
}
