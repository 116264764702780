import * as types from '../types'

const initialState = {
    localTractorNameList: null,
    error: null,
}

export default function localTractorNameReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOCAL_TRACTOR_NAME:
            let nameList = state.localTractorNameList
                ? [...state.localTractorNameList]
                : []

            let found = nameList.find(
                (item) => item.id === action.payload.requestLocalTractorName.id
            )

            if (found) {
                nameList = nameList.filter(
                    (item) =>
                        item.id !== action.payload.requestLocalTractorName.id
                )
            }

            return {
                ...state,
                localTractorNameList: [
                    ...nameList,
                    action.payload.requestLocalTractorName,
                ],
            }

        case types.RESET_LOCAL_TRACTOR_NAME:
            return {
                ...state,
                localTractorNameList: null,
                error: null,
            }

        default:
            return state
    }
}
