import * as types from '../types'

const initialState = {
    loading: false,
    requestDeviceID: null,
    resAllEvents: null,
    error: null,
}

export default function getDayEventsReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_DAY_EVENTS_REQUEST:
            return {
                ...state,
                loading: true,
                requestDeviceID: action.payload.requestDeviceID,
            }

        case types.GET_DAY_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                resAllEvents: action.payload.resAllEvents,
            }

        case types.GET_DAY_EVENTS_FAILURE:
            return {
                ...state,
                loading: false,
                requestDeviceID: null,
                resAllEvents: null,
                error: action.payload.error,
            }

        case types.GET_DAY_EVENTS_RESET:
            return {
                ...state,
                loading: false,
                requestDeviceID: null,
                resAllEvents: null,
                error: null,
            }

        default:
            return state
    }
}
