import * as types from '../types'

export const tractorModelRequest = ({ requestTractorModelId }) => {
    return {
        type: types.TRACTOR_MODEL_REQUEST,
        payload: { requestTractorModelId },
    }
}

export const tractorModelSuccess = ({ resTractorModel }) => {
    return {
        type: types.TRACTOR_MODEL_SUCCESS,
        payload: {
            resTractorModel,
        },
    }
}

export const tractorModelFailure = (error) => {
    return {
        type: types.TRACTOR_MODEL_FAILURE,
        payload: {},
        error: error,
    }
}
