import React, { useState } from 'react'
import { makeStyles, withTheme } from '@material-ui/core/styles'
// import Mahindra from "../assets/mahindra.svg";
import Logo from '../assets/home/Logo.svg'
import Nortification from '../assets/home/notifications.svg'
import Person from '../assets/home/person.svg'
import { AutoComplete } from '../elements'
import { connect } from 'react-redux'
import { logoutRequest } from '../store/actions/logoutAction'
import { loginUpdate } from '../store/actions/loginAction'
import { fetchNotificationRequest } from '../store/actions/fetchNotificationAction'
import { selectDate as selectDateUtil } from '../store/actions/selectDateAction'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Tooltip } from '@material-ui/core'

import Close from '../assets/home-tractor/WhiteClose.svg'
import DarkClose from '../assets/home-tractor/close.svg'
import Phone from '../assets/phone.svg'

import Modal from '@material-ui/core/Modal'
import { AnimatePresence, motion } from 'framer-motion'
import { List, Category } from '../subcomponents'
import { realtimeReset } from '../store/actions/realtimeRequestAction'
import { resetLocalTractorNameAction } from '../store/actions/localTractorNameAction'
import redirect from '../utils/redirect'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    nav: {
        width: '100%',
        height: '60px',
        background: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logo: {
        marginLeft: '1.3em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    navIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '20px',
        marginRight: '1.3em',
    },
    icon: {
        width: '40px !important',
        height: '40px !important',
        borderRadius: '50%',
        display: 'grid',
        placeContent: 'center',
        cursor: 'pointer',
    },
    nortification: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100vh',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
        overflowY: 'auto',
    },
    menuPaper: {
        boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
    },
    contactUs: {
        position: 'absolute',
        width: '400px',
        height: '120px',
        top: '20%',
        left: '35%',
        borderRadius: '4px',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
        overflowY: 'auto',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    privacy: {
        position: 'absolute',
        width: '700px',
        height: '600px',
        top: '10%',
        left: '35%',
        borderRadius: '4px',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
        overflowY: 'auto',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    contactUsHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > p': {
            cursor: 'pointer',
        },
    },
    contactUsDetail: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        '& > a': {
            textDecoration: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            lineHeight: '1.8',
            color: '#000',
        },
    },
    privacyDetail: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const Navbar = ({
    tractors,
    realtimeList,
    onPositionChange,
    setSelectedTractor,
    onShowTrips,
    setShow,
    show,
    showTracking,
    ...props
}) => {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)
    const [selected, setSelected] = useState(false)
    const [notificationValue, setNotificationValue] = useState('')

    const handleProfileMenuClose = () => {
        setAnchorEl(null)
    }

    const handleProfileMenuClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const [nortificationOpen, setNortificationOpen] = useState(false)
    const [contactUsOpen, setContactUsOpen] = useState(false)
    const location = useLocation()

    const handleLogout = () => {
        const queryParams = new URLSearchParams(window.location.search)

        const pin = queryParams.get('pin')
        const pinName = queryParams.get('pin_name')

        props.resetRealtime()
        props.localNameAction()
        props.logout()

        window.location.href =
            '/login?redir=' + redirect(location.pathname, pin, pinName)
    }

    return (
        <div className={classes.nav}>
            <div className={classes.logo}>
                <img src={Logo} alt="logo" />
            </div>
            {!showTracking && (
                <AutoComplete
                    data={tractors}
                    realtimeList={realtimeList}
                    onPositionChange={onPositionChange}
                    setSelectedTractor={setSelectedTractor}
                    onShowTrips={onShowTrips}
                    setShow={setShow}
                    show={show}
                />
            )}
            <div className={classes.navIcons}>
                <div
                    className={classes.icon}
                    onClick={() => {
                        setNortificationOpen(!nortificationOpen)
                        props.notification(props.userId)
                    }}
                >
                    <img src={Nortification} alt="nortifications" />
                </div>
                <div
                    className={classes.icon}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleProfileMenuClick}
                >
                    <img src={Person} alt="profile" />
                </div>
            </div>
            <Menu
                elevation={0}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{ paper: classes.menuPaper }}
            >
                <Tooltip title="See Dashboard in New Tab">
                    <MenuItem
                        onClick={() => {
                            window.open(
                                `https://www.carnot-clientanalytics.com/dashboards`,
                                '_blank'
                            )
                            handleProfileMenuClose()
                        }}
                    >
                        Analytics Dashboard
                    </MenuItem>
                </Tooltip>
                <MenuItem
                    onClick={() => {
                        window.open(
                            `https://amk-android-docs.s3.ap-south-1.amazonaws.com/AMKLegalDocs/Privacy+Policy+Krish-e+Smart+Kit+app.pdf`,
                            '_blank'
                        )
                        handleProfileMenuClose()
                    }}
                >
                    Privacy Policy
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setContactUsOpen(!contactUsOpen)
                        handleProfileMenuClose()
                    }}
                >
                    Contact Us
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <AnimatePresence>
                <Modal
                    open={nortificationOpen}
                    onClose={() => setNortificationOpen(!nortificationOpen)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: '420px' }}
                        transition={{ duration: 0.3 }}
                        className={classes.nortification}
                    >
                        {!selected ? (
                            <Category
                                Close={Close}
                                setNortificationOpen={setNortificationOpen}
                                nortificationOpen={nortificationOpen}
                                setSelected={setSelected}
                                setNotificationValue={setNotificationValue}
                                tractorName={
                                    props.tractor?.name ??
                                    props.allTractors[0].name
                                }
                            />
                        ) : (
                            <List
                                Close={Close}
                                setNortificationOpen={setNortificationOpen}
                                nortificationOpen={nortificationOpen}
                                notificationValue={notificationValue}
                                setSelected={setSelected}
                                tractorName={
                                    props.tractor?.name ??
                                    props.allTractors[0].name
                                }
                            />
                        )}
                    </motion.div>
                </Modal>
            </AnimatePresence>
            <AnimatePresence>
                <Modal
                    open={contactUsOpen}
                    onClose={() => setContactUsOpen(!contactUsOpen)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className={classes.contactUs}>
                        <div className={classes.contactUsHeader}>
                            <span>Contact Us</span>
                            <p onClick={() => setContactUsOpen(!contactUsOpen)}>
                                <img src={DarkClose} alt="dark-close" /> close
                            </p>
                        </div>
                        <div className={classes.contactUsDetail}>
                            <img src={Phone} alt="phone-icon" />
                            <a href="tel:+91-966-783-4835">+91-96678 34835</a>
                        </div>
                    </div>
                </Modal>
            </AnimatePresence>
        </div>
    )
}

const mapStateToProps = (state) => ({
    notify: state.fetchNotificationReducer.data,
    id: state.tractorReducer?.tractor?.id,
    tractor: state.tractorReducer.tractor,

    loginTractors: state.loginReducer.tractors,
    allTractors: state.loginReducer.allTractors,
    status: state.loginReducer.status,
    message: state.loginReducer.message,
    loginId: state.loginReducer.id,
    name: state.loginReducer.name,
    phone: state.loginReducer.phone,
    mm_userid: state.loginReducer.phone,
    auth_token: state.loginReducer.auth_token,
    realTimes: state.loginReducer.realTimes,

    datesLoading: state.tractorReducer.loading,
    dates: state.tractorReducer.dates,
    userId: state.loginReducer.id,
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutRequest())
    },
    resetRealtime: () => {
        dispatch(realtimeReset())
    },
    notification: (id) => {
        dispatch(fetchNotificationRequest({ id }))
    },
    selectDate: ({ date }) => {
        dispatch(selectDateUtil({ date }))
    },
    makeLoginUpdate: ({ tractors, id, name, phone, auth_token }) => {
        dispatch(loginUpdate({ tractors, id, name, phone, auth_token }))
    },
    localNameAction: () => {
        dispatch(resetLocalTractorNameAction())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Navbar))
