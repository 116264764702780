import * as types from '../types'

export const getTodayStatusRequest = ({ requestUserID }) => {
    return {
        type: types.GET_TODAY_STATUS_REQUEST,
        payload: { requestUserID },
    }
}

export const getTodayStatusSuccess = ({ resTodayStatus }) => {
    return {
        type: types.GET_TODAY_STATUS_SUCCESS,
        payload: {
            resTodayStatus,
        },
    }
}

export const getTodayStatusFailure = (error) => {
    return {
        type: types.GET_TODAY_STATUS_FAILURE,
        payload: {},
        error: error,
    }
}
