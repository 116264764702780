import React, { useState } from 'react'
import { makeStyles, withTheme } from '@material-ui/core/styles'
import Warning from '../assets/Warning.svg'
import Cancel from '../assets/cancel.svg'
import Contact from '../assets/contact.svg'
import Modal from '@material-ui/core/Modal'
import getTractorImages from '../utils/getTractorImages'

const useStyles = makeStyles((theme) => ({
    subscription: {
        width: '525px',
        height: '586px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
        overflowY: 'scroll',
        borderRadius: '16px',
        padding: '25px',
    },
    subscriptionTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        clear: 'both',
    },
    subscriptionHeader: {
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '32px',
        marginTop: '10px',
    },
    subscriptionMessage: {
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '23.44px',
        marginTop: '10px',
    },
    supportNumber: {
        color: '#652078',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '28.31px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    subscriptionVertical: {
        width: '100%',
        height: '1px',
        background: '#D7D7D7',
        marginTop: '30px',
    },
    rechargeHeading: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18.75px',
        margin: '10px 0px',
    },
    expiredTractorName: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '21px',
    },
    expiredTractorDate: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18.75px',
    },
}))

const SubscriptionExpired = ({ tractors, ...props }) => {
    const classes = useStyles()
    const tractorsLength = tractors?.filter(
        (trac) => trac?.subscription?.is_valid === false
    )
    const [subscriptionOpen, setSubscriptionOpen] = useState(true)

    return (
        <Modal
            open={subscriptionOpen}
            onClose={() => setSubscriptionOpen(!subscriptionOpen)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.subscription}>
                <div
                    style={{
                        position: 'relative',
                        float: 'right',
                        cursor: 'pointer',
                    }}
                    onClick={() => setSubscriptionOpen(!subscriptionOpen)}
                >
                    <img src={Cancel} alt="cancel" />
                </div>
                <div className={classes.subscriptionTop}>
                    <img src={Warning} alt="Warning" />
                    <div className={classes.subscriptionHeader}>
                        Recharge expired for {tractorsLength?.length} tractors.
                    </div>
                    <div className={classes.subscriptionMessage}>
                        Please contact on the support helpline number
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <span className={classes.supportNumber}>
                            <img src={Contact} alt="contact" />
                            9152-915200
                        </span>
                    </div>
                    <div className={classes.subscriptionVertical}></div>
                </div>
                <div className={classes.subscriptionBottom}>
                    <div className={classes.rechargeHeading}>
                        Recharge expired for :
                    </div>
                    {tractors?.map((tractor, index) =>
                        !tractor?.subscription?.is_valid ? (
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                    marginTop: '10px',
                                }}
                                key={index}
                            >
                                <div>
                                    <img
                                        src={
                                            tractor.tractor_img_url !== null
                                                ? tractor.tractor_img_url
                                                : getTractorImages(
                                                      tractor.model,
                                                      tractor.make
                                                  )
                                        }
                                        alt="tractor_image"
                                        width="80px"
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <span
                                        className={classes.expiredTractorName}
                                    >
                                        {tractor.name}
                                    </span>
                                    <span
                                        className={classes.expiredTractorDate}
                                    >
                                        Recharge expired on :{' '}
                                        {tractor?.subscription_due_date}
                                    </span>
                                </div>
                            </div>
                        ) : null
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default withTheme(SubscriptionExpired)
