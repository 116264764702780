import * as types from '../types'

const initialState = {
    date: new Date(),
}

const selectDateReducer = (state = initialState, action) => {
    if (action.type === types.SELECT_DATE) {
        return {
            ...state,
            date: action.payload.date,
        }
    } else {
        return state
    }
}

export default selectDateReducer
