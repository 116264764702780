import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import {
    otpRequestFailure,
    otpRequestSuccess,
} from '../actions/otpRequestAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'

function* asyncOtpRequest(action) {
    try {
        yield put(clearErrors())
        let otpSuccess = yield call(_api.makeOTPRequestCall, action.payload)
        yield put(
            otpRequestSuccess({
                otpSuccess,
                phone: action.payload,
            })
        )
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(otpRequestFailure(e))
    }
}

export default function* otpRequestSaga() {
    yield takeLatest(types.OTP_REQUEST, asyncOtpRequest)
}
