import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useAutocomplete from '@material-ui/lab/useAutocomplete'

import { connect } from 'react-redux'

import { tractorRequest } from '../store/actions/tractorAction'
import { daySummaryRequest } from '../store/actions/daySummaryAction'

const useStyles = makeStyles((theme) => ({
    autoCompleteBoxWrapper: {
        flex: 1,
        maxWidth: '600px',
        display: 'grid',
        placeContent: 'center',
    },
    autoCompleteBox: {
        minWidth: '300px',
        height: '32px',
        background: '#FFFFFF',
        borderRadius: '4px',
    },
    input: {
        width: '627px',
        height: '32px',
        outline: 'none',
        border: '1px solid #CCCCCC',
        borderRadius: '4px',
        padding: '0.5em',
    },
    listbox: {
        marginTop: '0.5em',
        borderRadius: '4px',
        width: '627px',
        margin: 0,
        padding: 0,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: 350,
        outline: 'none',
        '& li[data-focus="true"]': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#ECBCC0',
            color: 'white',
        },
    },
    optionItem: {
        padding: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

        '& > h3': {
            margin: '0.3em 0',
            padding: 0,
        },
    },
    tags: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',

        '& > span': {
            background: 'rgba(0,0,0,0.1)',
            padding: '0.2em 0.7em',
            borderRadius: '10px',
            fontSize: '0.8em',
        },
    },
}))

function AutoComplete({
    data,
    onPositionChange,
    setSelectedTractor,
    onShowTrips,
    show,
    setShow,
    ...props
}) {
    const classes = useStyles()
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: data.filter((item) => item?.subscription?.is_valid === true),
        getOptionLabel: (option, index) => {
            return (
                option.device_pk +
                ' - ' +
                option.name +
                ' (' +
                option.model +
                ' - ' +
                option.make +
                ')'
            )
        },
        onChange: (event, value) => {
            if (value) {
                let index
                for (let t = 0; t < data.length; t++) {
                    if (data[t].device_pk === value.device_pk) {
                        index = t
                        break
                    }
                }
                onPositionChange(index)
                setSelectedTractor((prev) => {
                    if (
                        !show &&
                        prev &&
                        prev.tractor.device_pk === value.device_pk
                    ) {
                        return prev
                    } else {
                        props.selectTractor({
                            tractor: value,
                            realTime:
                                props.realtimeList.find(
                                    (rt) => rt.fb_key === value.fb_key
                                ) ?? props.realtimeList[index],
                        })
                        props.daySummary({
                            tractor: value,
                            realTime:
                                props.realtimeList.find(
                                    (rt) => rt.fb_key === value.fb_key
                                ) ?? props.realtimeList[index],
                        })

                        setShow(false)
                        onShowTrips(false)
                        return {
                            tractor: value,
                            index,
                            realTime:
                                props.realtimeList.find(
                                    (rt) => rt.fb_key === value.fb_key
                                ) ?? props.realtimeList[index],
                        }
                    }
                })
            }
        },
    })

    return (
        <div className={classes.autoCompleteBoxWrapper}>
            <div className={classes.autoCompleteBox}>
                <div {...getRootProps()}>
                    <input
                        className={classes.input}
                        placeholder="Search by Tractor Name, Device Id & Model"
                        {...getInputProps()}
                    />
                </div>
                {groupedOptions.length > 0 ? (
                    <ul className={classes.listbox} {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                            <li
                                {...getOptionProps({ option, index })}
                                className={classes.optionItem}
                            >
                                <h3>
                                    {option.name} - {option.device_pk}
                                </h3>
                                <div className={classes.tags}>
                                    <span>{option.model}</span>
                                    <span>{option.make}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    selectTractor: ({ tractor, realTime }) => {
        dispatch(tractorRequest({ tractor, realTime }))
    },
    daySummary: ({ realTime, tractor, date }) => {
        dispatch(daySummaryRequest({ realTime, tractor, date }))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete)
