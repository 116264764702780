import * as types from '../types'

const initialState = {
    loading: false,
    requestUserID: null,
    resTodayStatus: null,
    error: null,
}

export default function getTodayStatusReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_TODAY_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                requestUserID: action.payload.requestUserID,
            }

        case types.GET_TODAY_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                resTodayStatus: action.payload.resTodayStatus,
            }

        case types.GET_TODAY_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                requestUserID: null,
                resTodayStatus: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}
