import arjun from "../assets/tractor-images/arjun.png";
import bhoomiputra1 from "../assets/tractor-images/bhoomiputra.png";
import bhoomiputra from "../assets/tractor-images/bhoomiputra_demo.png";
import jivo from "../assets/tractor-images/jivo.png";
import johnDeere from "../assets/tractor-images/john_deere.png";
import masseyFerguson from "../assets/tractor-images/massey_ferguson.png";
import sarpanch from "../assets/tractor-images/sarpanch.png";
import sonalika from "../assets/tractor-images/sonalika.png";
import swaraj744 from "../assets/tractor-images/swaraj_744.png";
import swaraj843 from "../assets/tractor-images/swaraj_843.png";
import swaraj855 from "../assets/tractor-images/swaraj_855_xm.png";
import swaraj963 from "../assets/tractor-images/swaraj_963.png";
import yuvo from "../assets/tractor-images/yuvo.png";
import yuvraj from "../assets/tractor-images/yuvraj.png";

function getTractorImages(model, make) {
  make = make ?? "Mahindra";
  model = model.toLowerCase().trim();
  make = make.toLowerCase().trim();
  if (make.includes("mahindra")) {
    if (model.includes("yuvraj")) {
      return yuvraj;
    } else if (model.includes("yuvo")) {
      return yuvo;
    } else if (model.includes("jivo")) {
      return jivo;
    } else if (model.includes("novo") || model.includes("arjun")) {
      return arjun;
    } else if (model.includes("sarpanch")) {
      return sarpanch;
    } else if (model.includes("bhoomiputra")) {
      return bhoomiputra1;
    } else if (model.includes("track harvester")) {
      return bhoomiputra;
    } else {
      return bhoomiputra;
    }
  } else if (make.includes("swaraj")) {
    if (model.includes("744")) return swaraj744;
    else if (model.includes("855")) return swaraj855;
    else if (model.includes("963")) return swaraj963;
    else return swaraj843;
  } else if (make.includes("massey")) {
    return masseyFerguson;
  } else if (make.includes("john")) {
    return johnDeere;
  } else if (make.includes("sonalika")) {
    return sonalika;
  } else {
    return bhoomiputra;
  }
}

export default getTractorImages;
