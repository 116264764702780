import { takeLatest, call, put } from 'redux-saga/effects'
import {
    getDayEventsSuccess,
    getDayEventsFailure,
} from '../actions/getDayEventsAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncGetDayEventsSaga(action) {
    try {
        yield put(clearErrors())
        let resAllEvents = yield call(_api.getDayEventsAPI, {
            requestDeviceID: action.payload.requestDeviceID,
        })
        yield put(getDayEventsSuccess({ resAllEvents }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(getDayEventsFailure({ error: e }))
    }
}
export default function* getDayEventsSaga() {
    yield takeLatest(types.GET_DAY_EVENTS_REQUEST, asyncGetDayEventsSaga)
}
