import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import ForumIcon from '@material-ui/icons/Forum'

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    nortification: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100vh',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
    },
    nortificationHeader: {
        width: '420px',
        height: '60px',
        background: theme.palette.primary.main,
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#FFFFFF',
        position: 'fixed',
    },
    cross: {
        cursor: 'pointer',
    },
    card: {
        height: '30px',
        margin: '30px',
    },
    tractor: {
        color: 'black',
    },
}))

function Category({
    setNortificationOpen,
    Close,
    nortificationOpen,
    setSelected,
    setNotificationValue,
    tractorName,
    index,
    ...props
}) {
    const classes = useStyles()
    const [securityCounter, setSecurityCounter] = useState(0)
    const [healthCounter, setHealthCounter] = useState(0)
    const [subscriptionCounter, setSubscriptionCounter] = useState(0)
    const [activiytCounter, setActivityCounter] = useState(0)
    const [otherCounter, setOtherCounter] = useState(0)

    useEffect(() => {
        if (props.notify) {
            const allAlerts = props.notify?.flatMap(
                (obj) => Object.values(obj)[0]
            )
            let count1 = 0
            let count2 = 0
            let count3 = 0
            let count4 = 0
            let count5 = 0
            if (allAlerts) {
                allAlerts.forEach((val) => {
                    if (val.fleet_category === 'Security Notifications') {
                        count1 = count1 + 1
                    } else if (val.fleet_category === 'Health Notifications') {
                        count2 = count2 + 1
                    } else if (val.fleet_category === 'Subscription Alert') {
                        count3 = count3 + 1
                    } else if (
                        val.fleet_category === 'Activity Notifications'
                    ) {
                        count4 = count4 + 1
                    } else if (val.fleet_category === 'Other Alert') {
                        count5 = count5 + 1
                    }
                })
            }
            setSecurityCounter(count1)
            setHealthCounter(count2)
            setSubscriptionCounter(count3)
            setActivityCounter(count4)
            setOtherCounter(count5)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.notify])

    const item = [
        {
            title: 'SECURITY NOTIFICATIONS',
            subtitle: 'Notification related to your trafic security',
            filter: 'Security Notifications',
            count: securityCounter,
        },
        {
            title: 'HEALTH NOTIFICATIONS',
            subtitle: 'Notification related to your Tractor Health',
            filter: 'Health Notifications',
            count: healthCounter,
        },
        {
            title: 'ACTIVITY NOTIFICATIONS',
            subtitle: 'Notification related to your Tractor Activity',
            filter: 'Activity Notifications',
            count: activiytCounter,
        },
        {
            title: 'SUBSCRIPTION NOTIFICATIONS',
            subtitle: 'Notification related to Simha Kit Subscription',
            filter: 'Subscription Alert',
            count: subscriptionCounter,
        },
        {
            title: 'OTHER NOTIFICATIONS',
            subtitle: 'Other Generic notifications',
            filter: 'Other Alert',
            count: otherCounter,
        },
    ]
    return (
        <>
            <div className={classes.nortificationHeader}>
                <div
                    style={{
                        flex: '1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0 1.3em',
                    }}
                >
                    <p
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Alerts
                    </p>
                    <span
                        onClick={() => setNortificationOpen(!nortificationOpen)}
                        className={classes.cross}
                    >
                        {' '}
                        <img src={Close} alt="close" /> Close
                    </span>
                </div>
            </div>
            <div
                style={{ scrollPaddingTop: '80px', margin: '60px auto' }}
                className={classes.column}
            >
                {item.map((val, key) => {
                    return (
                        <Card
                            key={key}
                            onClick={() => {
                                setSelected(true)
                                setNotificationValue(val.filter)
                            }}
                            style={{
                                cursor: 'pointer',
                                margin: '30px',
                                height: '80px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                border: 'groove',
                                boxShadow: '3px 4px #888888',
                            }}
                        >
                            <ForumIcon
                                style={{ color: '#BE1E2D', margin: '14px' }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                <p style={{ fontSize: '14px' }}>
                                    {val.title} <b>({val.count})</b>
                                </p>
                                <p>{val.subtitle}</p>
                            </div>
                        </Card>
                    )
                })}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    notify: state.fetchNotificationReducer.data,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Category)
