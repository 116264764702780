import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import {
    loginRequestSuccess,
    loginRequestFailure,
} from '../actions/loginAction'
import {
    logoutRequestSuccess,
    logoutRequestFailure,
} from '../actions/logoutAction'
import { otpReset } from '../actions/otpRequestAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'

import { goOffline } from '../../firebase'

function* asyncLoginRequest(action) {
    try {
        yield put(clearErrors())
        const res = yield call(_api.fetchTractorListData, {
            phoneNumber: action.payload.phone,
            otp: action.payload.otp,
        })
        if (res.status && res.data.tractors.length > 0) {
            sessionStorage.setItem(
                'access_token',
                res.data.auth_tokens.access_token
            )
            sessionStorage.setItem(
                'refresh_token',
                res.data.auth_tokens.refresh_token
            )
            const realtimeTractorData = yield call(
                _api.fetchRealtimeTractorData,
                {
                    phone: res.data.phone,
                }
            )
            let realCheckData = []
            if (realtimeTractorData && realtimeTractorData.status) {
                for (let i = 0; i < res.data.tractors.length; i++) {
                    realCheckData.push({
                        latitude:
                            realtimeTractorData.data[
                                res.data.tractors[i]['device_pk']
                            ]['latitude'],
                        longitude:
                            realtimeTractorData.data[
                                res.data.tractors[i]['device_pk']
                            ]['longitude'],
                        fuel_level_dashboard:
                            realtimeTractorData.data[
                                res.data.tractors[i]['device_pk']
                            ]['fuel_level_dashboard'],
                        lock_status:
                            realtimeTractorData.data[
                                res.data.tractors[i]['device_pk']
                            ]['lock_status'],
                        orientation:
                            realtimeTractorData.data[
                                res.data.tractors[i]['device_pk']
                            ]['orientation'],
                        rpm: realtimeTractorData.data[
                            res.data.tractors[i]['device_pk']
                        ]['rpm'],
                        speed: realtimeTractorData.data[
                            res.data.tractors[i]['device_pk']
                        ]['speed'],
                        status: realtimeTractorData.data[
                            res.data.tractors[i]['device_pk']
                        ]['status'],
                        time_stamp:
                            realtimeTractorData.data[
                                res.data.tractors[i]['device_pk']
                            ]['time_stamp'],
                        sts: realtimeTractorData.data[
                            res.data.tractors[i]['device_pk']
                        ]['sts'],
                        fb_key: res.data.tractors[i]['fb_key'],
                    })
                }
            }
            yield put(
                loginRequestSuccess({
                    data: res.data,
                    status: res.status,
                    message: res.message,
                    realTimes: realCheckData,
                })
            )
        } else if (!res.status) {
            yield put(loginRequestFailure({ message: res.message }))
        } else if (res.data.new_user || res.data.tractors.length === 0) {
            yield put(
                loginRequestFailure({
                    message:
                        'No Associated Tractor Found Please add tractor to your account from our app and then continue',
                })
            )
        } else {
            yield put(
                loginRequestSuccess({
                    data: res.data,
                    status: res.status,
                    message: res.message,
                })
            )
        }
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(loginRequestFailure(e))
    }
}

function* asyncLoginUpdateRequest(action) {
    try {
        yield put(clearErrors())
        const realTimesUpdate = yield action.payload.tractors
        yield put(
            loginRequestSuccess({
                data: {
                    id: action.payload.id,
                    name: '',
                    new_user: false,
                    phone: action.payload.phone,
                    support_no: 9152915200,
                    dob: '',
                    auth_token: action.payload.auth_token,
                    mm_is_reg: false,
                    mm_phone: -1,
                    mm_userid: -1,
                    tractors: action.payload.tractors,
                },
                status: action.payload.status,
                message: action.payload.message,
                realTimes: realTimesUpdate,
            })
        )
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(loginRequestFailure(e))
    }
}

function* asyncLogoutRequest(action) {
    try {
        goOffline()
        yield put(otpReset())
        yield put(logoutRequestSuccess())
    } catch (e) {
        yield put(logoutRequestFailure(e))
    }
}

export default function* loginRequestSaga() {
    yield put(clearErrors())
    yield takeLatest(types.LOGIN_REQUEST, asyncLoginRequest)
}

export function* loginUpdateSaga() {
    yield put(clearErrors())
    yield takeLatest(types.LOGIN_UPDATE, asyncLoginUpdateRequest)
}

export function* logoutRequestSaga() {
    yield takeLatest(types.LOGOUT_REQUEST, asyncLogoutRequest)
}
