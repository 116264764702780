import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/sidebar'
import SubscriptionExpired from '../../components/SubscriptionExpired'
import { useParams, useLocation } from 'react-router-dom'

const Dashboard = ({
    tractors,
    realtimeList,
    showTracking,
    setShowTracking,
}) => {
    const { id } = useParams()
    const location = useLocation()
    const [show, setShow] = useState(true)
    const [selectedTractor, setSelectedTractor] = useState(null)
    const [checkId, setCheckId] = useState(false)

    const [position, setposition] = useState(0)
    const [showTrips, setshowTrips] = useState(false)

    const onSelectedTractorChanged = (newPostion) => {
        setposition(newPostion)
    }

    useEffect(() => {
        if (location.pathname.startsWith('/tracking')) {
            if (tractors.find((tr) => tr.device_pk === parseInt(id))) {
                setSelectedTractor({
                    tractor: tractors[0],
                    index: 0,
                    realTime:
                        realtimeList.find(
                            (rt) => rt.fb_key === tractors[0].fb_key
                        ) ?? realtimeList[0],
                })
            } else {
                setCheckId(true)
            }
        } else {
            setSelectedTractor({
                tractor: tractors[0],
                index: 0,
                realTime:
                    realtimeList.find(
                        (rt) => rt.fb_key === tractors[0].fb_key
                    ) ?? realtimeList[0],
            })
        }
    }, [location, id, tractors, realtimeList])

    const onShowTrips = (hideShow) => {
        setshowTrips(hideShow)
    }

    const tractorsLength = tractors?.filter(
        (trac) => trac?.subscription?.is_valid === false
    )

    return (
        <>
            {!checkId && (
                <div>
                    <Navbar
                        tractors={tractors}
                        realtimeList={realtimeList}
                        onPositionChange={onSelectedTractorChanged}
                        setSelectedTractor={setSelectedTractor}
                        onShowTrips={onShowTrips}
                        setShow={setShow}
                        show={show}
                        showTracking={showTracking}
                        setShowTracking={setShowTracking}
                    />
                    <Sidebar
                        tractors={tractors}
                        realtimeList={realtimeList}
                        show={show}
                        setShow={setShow}
                        selectedTractor={selectedTractor}
                        setSelectedTractor={setSelectedTractor}
                        position={position}
                        setposition={setposition}
                        showTrips={showTrips}
                        setshowTrips={setshowTrips}
                        onSelectedTractorChanged={onSelectedTractorChanged}
                        onShowTrips={onShowTrips}
                        showTracking={showTracking}
                        setShowTracking={setShowTracking}
                    />
                    {tractorsLength?.length > 0 && !showTracking && (
                        <SubscriptionExpired tractors={tractors} />
                    )}
                </div>
            )}
            {checkId && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                    }}
                >
                    <p style={{ fontSize: '18px', fontWeight: '600' }}>
                        Oops! We couldn't find that Device ID
                    </p>
                </div>
            )}
        </>
    )
}

export default Dashboard
