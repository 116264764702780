import Medium from '../assets/home-tractor/speedo-meter.svg'
import Low from '../assets/home-tractor/low-fuel.svg'
import High from '../assets/home-tractor/high-fuel.svg'

export const getFuelMeterTank = (maxFuel, fuelInLitres) => {
    let fuelPercent = (fuelInLitres / maxFuel) * 100

    if (fuelPercent <= 30) {
        return Low
    } else if (fuelPercent <= 60) {
        return Medium
    } else {
        return High
    }
}

export const getFuelMeterText = (maxFuel, fuelInLitres) => {
    let fuelPercent = (fuelInLitres / maxFuel) * 100

    if (fuelPercent <= 30) {
        return 'Low'
    } else if (fuelPercent <= 60) {
        return 'Medium'
    } else {
        return 'Full'
    }
}
