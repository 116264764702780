import { takeLatest, call, put } from 'redux-saga/effects'
import {
    realtimeRequestFailure,
    realtimeRequestSuccess,
} from '../actions/realtimeRequestAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncRealtimeSaga(action) {
    try {
        yield put(clearErrors())
        let realtimeData = yield call(_api.fetchRealtimeTractorData, {
            phone: action.payload.phone,
        })
        yield put(realtimeRequestSuccess({ realtimeData }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(realtimeRequestFailure({ error: e }))
    }
}
export default function* realtimeSaga() {
    yield takeLatest(types.REALTIME_REQUEST, asyncRealtimeSaga)
}
