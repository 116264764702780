import { put, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import {
    updateSuccess,
    updateFailure,
} from '../actions/updateTractorDataAction'
import { returnErrors, clearErrors } from '../actions/errorAction'

import { parseIndividualRealtimeString } from '../../firebase'

function* asyncUpdateRequest(action) {
    try {
        yield put(clearErrors())
        if (action.payload.fb_key) {
            const realTime = yield parseIndividualRealtimeString(
                action.payload.fb_key
            )
            yield put(
                updateSuccess({
                    realTime,
                })
            )
        }
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(updateFailure(e))
    }
}

export default function* updateSaga() {
    yield put(clearErrors())
    yield takeLatest(types.UPDATE_REQUEST, asyncUpdateRequest)
}
