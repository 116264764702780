import { takeLatest, call, put } from 'redux-saga/effects'
import {
    getAllGeofenceSuccess,
    getAllGeofenceFailure,
} from '../actions/getAllGeofenceAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncGetAllGeofenceSaga(action) {
    try {
        yield put(clearErrors())
        let resAllGeofence = yield call(_api.getAllGeofence, {
            requestTractorId: action.payload.requestTractorId,
        })
        yield put(getAllGeofenceSuccess({ resAllGeofence }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(getAllGeofenceFailure({ error: e }))
    }
}
export default function* getAllGeofenceSaga() {
    yield takeLatest(types.GET_ALL_GEOFENCE_REQUEST, asyncGetAllGeofenceSaga)
}
