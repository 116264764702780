import * as types from '../types'

export const localTractorNameAction = ({ requestLocalTractorName }) => ({
    type: types.LOCAL_TRACTOR_NAME,
    payload: {
        requestLocalTractorName,
    },
})

export const resetLocalTractorNameAction = () => ({
    type: types.RESET_LOCAL_TRACTOR_NAME,
})
