import { takeLatest, call, put } from 'redux-saga/effects'
import {
    editGeofenceFailure,
    editGeofenceSuccess,
} from '../actions/editGeofenceAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncEditGeofenceSaga(action) {
    try {
        yield put(clearErrors())
        let resEditGeofence = yield call(_api.editGeofenceAPI, {
            requestEditGeofence: action.payload.requestEditGeofence,
        })
        yield put(editGeofenceSuccess({ resEditGeofence }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(editGeofenceFailure({ error: e }))
    }
}
export default function* editGeofenceSaga() {
    yield takeLatest(types.EDIT_GEOFENCE_REQUEST, asyncEditGeofenceSaga)
}
