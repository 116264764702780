import * as types from '../types'

export const fetchNotificationRequest = ({ id }) => {
    return {
        type: types.FETCH_NOTIFICATION_REQUEST,
        payload: {
            id,
        },
    }
}

export const fetchNotificationSuccess = ({ data, status }) => {
    return {
        type: types.FETCH_NOTIFICATION_SUCCESS,
        payload: {
            data,
            status,
        },
    }
}

export const fetchNotificationFailure = ({ e }) => {
    return {
        type: types.FETCH_NOTIFICATION_FAILURE,
        payload: {},
        error: e,
    }
}
