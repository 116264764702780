const redirect = (pathname, pin = '', pin_name = '') => {
    let redirectTo = pathname
    const queryParams = []

    if (pin) {
        queryParams.push(`pin=${encodeURIComponent(pin)}`)
    }
    if (pin_name) {
        queryParams.push(`pin_name=${encodeURIComponent(pin_name)}`)
    }

    if (queryParams.length > 0) {
        redirectTo += '?' + queryParams.join('&')
    }

    return encodeURIComponent(redirectTo)
}

export default redirect
