import * as types from '../types'

export const updateRequest = ({ fb_key }) => {
    return {
        type: types.UPDATE_REQUEST,
        payload: { fb_key },
    }
}

export const updateSuccess = ({ realTime }) => {
    return {
        type: types.UPDATE_SUCCESS,
        payload: {
            realTime,
        },
    }
}

export const updateFailure = (error) => {
    return {
        type: types.UPDATE_FAILURE,
        payload: {},
        error: error,
    }
}
