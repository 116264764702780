import { makeStyles } from '@material-ui/core/styles'
import React, { useRef, useState } from 'react'
import DownArrow from '../assets/downArrow.svg'
import upArrow from '../assets/upArrow.svg'

const useStyles = makeStyles((theme) => ({
    accordion__section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '5px',
    },
    accordion: {
        backgroundColor: '#EDF7F6',
        cursor: 'pointer',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 'none',
        outline: 'none',
        borderRadius: '4px',
    },
    accordion__title: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        textTransform: 'capitalize',
        color: '#000',
    },
    accordion__icon: {
        marginLeft: 'auto',
        transition: 'transform 0.6s ease',
    },
    rotate: {
        transform: 'rotate(90deg)',
    },
    accordion__content: {
        backgroundColor: '#fff',
        border: '1px solid #CDEFEC',
        borderRadius: '4px',
        overflowY: 'scroll',
    },
    accordion__text: {
        fontWeight: '400',
        fontSize: '11px',
        lineHeight: '15px',
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        // gap:"5px",
        width: '175px',
        justifyContent: 'space-evenly',
        padding: '10px',
    },
    active: {
        backgroundColor: '#edf7f6',
    },
    // vertical_line_wrapper:{
    //   width:"175px",
    //   display:"flex",
    //   alignItems:"center",
    //   justifyContent:"space-evenly",
    // },
    // vertical_line:{
    //   borderLeft: "1px solid green",
    //   height: "20px",
    // }
}))
const ListItem = ({ ...props }) => {
    const classes = useStyles()
    const [active, setActive] = useState(false)
    const content = useRef(null)
    const [height, setHeight] = useState('0px')

    const toggleAccordion = () => {
        setActive(!active)
        setHeight(active ? '0px' : `300px`)
    }

    return (
        <div className={classes.accordion__section}>
            <div
                className={`${classes.accordion} ${
                    active ? classes.active : ''
                }`}
                onClick={toggleAccordion}
            >
                <p className={classes.accordion__title}>{props.title}</p>
                <span style={{ marginLeft: '20px' }}>
                    {active ? (
                        <img src={upArrow} alt="up-arrow" />
                    ) : (
                        <img src={DownArrow} alt="down-arrow" />
                    )}
                </span>
            </div>
            <div
                ref={content}
                style={{ maxHeight: `${height}` }}
                className={classes.accordion__content}
            >
                {props.content &&
                    props.content.length > 0 &&
                    props.content.map((item, index) => {
                        return (
                            <>
                                <div
                                    className={classes.accordion__text}
                                    style={{ padding: '0px' }}
                                    key={index}
                                >
                                    <span style={{ width: '50px' }}>
                                        {item.time}
                                    </span>
                                    <img
                                        src={item.icon}
                                        alt="timeline-on"
                                        width="20px"
                                        height="20px"
                                    />
                                    <span style={{ width: '60px' }}>
                                        {item.title}
                                    </span>
                                </div>
                                {props.content.length - 1 !== index && (
                                    <div
                                        className={classes.accordion__text}
                                        style={{ padding: '0px' }}
                                        key={index}
                                    >
                                        <span style={{ width: '50px' }}></span>
                                        <span
                                            style={{
                                                borderLeft: '3px solid #edf7f6',
                                                height: '26px',
                                            }}
                                        />
                                        <span style={{ width: '60px' }}></span>
                                    </div>
                                )}
                            </>
                        )
                    })}
            </div>
        </div>
    )
}

export default ListItem
