import firebase from 'firebase'
import { config } from '../config'
firebase.initializeApp(config)

export const parseIndividualRealtimeString = async (fb_key) => {
    let presenceRef = firebase.database().ref('.info/connected')
    let presenceStatus = firebase.database().ref(`/status/${fb_key}`)

    presenceRef.on('value', function (snapshot) {
        if (snapshot.val()) {
            presenceStatus.on('value', function (dataSnap) {
                let currentCount = 0
                if (dataSnap.val() !== null) {
                    if (
                        Number.isInteger(dataSnap.val()) &&
                        dataSnap.val() <= 0
                    ) {
                        presenceStatus.set(currentCount + 1)
                    }
                } else {
                    currentCount++
                    presenceStatus.set(currentCount + 1)
                }
                presenceStatus.onDisconnect().set(0)
            })
        }
    })

    const realtimeReadRef = firebase.database().ref(`devices/${fb_key}`)
    const snapshot = await realtimeReadRef.once('value')

    const firebaseStr = snapshot.val()

    const refLatLon = firebase.database().ref(`references/${fb_key}`)
    const snapsho = await refLatLon.once('value')

    const firebaseRefLatLonStr = snapsho.val()

    const digits =
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const indiaLatMin = firebaseRefLatLonStr
        ? firebaseRefLatLonStr['lat']
        : 8.872694
    const indiaLonMin = firebaseRefLatLonStr
        ? firebaseRefLatLonStr['lon']
        : 67.372945
    const base = 25
    const timeStampMin = 20190101000000
    const keyStart = 2 * base + 2
    let hexSum = 0.0

    const intArray = []

    for (let i = 0; i < firebaseStr.length; i++) {
        const x = firebaseStr.charAt(i)
        intArray.push(digits.indexOf(x))
    }

    const valueArray = []
    let lastEndInd = 0

    for (let i = 1; i < intArray.length; i++) {
        const temp = intArray[i]
        if (temp > keyStart) {
            let data = []
            data.push(...intArray.slice(lastEndInd + 1, i))
            valueArray.push(data)
            lastEndInd = i
        }
    }

    let data = []
    data.push(...intArray.slice(lastEndInd + 1, intArray.length))
    valueArray.push(data)
    const finalArray = []
    for (let i = 0; i < valueArray.length; i++) {
        hexSum = 0.0
        let temp = valueArray[i]
        let da = temp.reverse()

        for (let j = 0; j < da.length; j++) {
            hexSum += da[j] * Math.pow(base, j)
        }
        finalArray.push(hexSum)
    }

    let temp = finalArray[8]
    temp += timeStampMin
    let _temp = temp.toString()
    let tempStr =
        _temp.substring(0, 4) +
        '-' +
        _temp.substring(4, 6) +
        '-' +
        _temp.substring(6, 8) +
        'T' +
        _temp.substring(8, 10) +
        ':' +
        _temp.substring(10, 12) +
        ':' +
        _temp.substring(12, 14) +
        'Z'

    let realtime = {
        fuel_level_dashboard: finalArray[0] / 100,
        latitude: finalArray[1] / 1000000.0 + indiaLatMin,
        longitude: finalArray[3] / 1000000.0 + indiaLonMin,
        lock_status: finalArray[2],
        orientation: finalArray[4],
        rpm: finalArray[5],
        speed: finalArray[6],
        status: finalArray[7],
        time_stamp: tempStr,
        sts: tempStr,
        fb_key,
    }

    // alert(JSON.stringify(realtime));

    return realtime
}

export const goOffline = () => {
    firebase.database().goOffline()
    firebase
        .app()
        .delete()
        .then(function () {
            firebase.initializeApp(config)
        })
}

export const goOnline = () => {
    firebase.database().goOnline()
}
