import React, { useState, useRef } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Checkbox } from '@material-ui/core'

const MultiSelect = ({
    items,
    label,
    placeholder,
    selectAllLabel,
    noOptionsText,
    limitTags,
    onChange,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([])
    const filterRef = useRef(null)
    const allSelected = items.length === selectedOptions.length
    const handleToggleOption = (selectedOptions) =>
        setSelectedOptions(selectedOptions)
    const handleClearOptions = () => setSelectedOptions([])
    const getOptionLabel = (option) => `${option.label}`

    const handleSelectAll = (isSelected) => {
        if (isSelected) {
            setSelectedOptions(filterRef.current)
        } else {
            handleClearOptions()
        }
    }

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected)
    }

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === 'select-option' || reason === 'remove-option') {
            if (
                selectedOptions.find((option) => option.value === 'select-all')
            ) {
                handleToggleSelectAll()
                return onChange(filterRef.current)
            } else {
                handleToggleOption && handleToggleOption(selectedOptions)
                return onChange(selectedOptions)
            }
        } else if (reason === 'clear') {
            handleClearOptions && handleClearOptions()
        }
    }
    const optionRenderer = (option, { selected }) => {
        const selectAllProps =
            option.value === 'select-all' ? { checked: allSelected } : {}
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        )
    }
    const inputRenderer = (params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
    )
    const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value
    const filter = createFilterOptions()
    return (
        <Autocomplete
            multiple
            size="small"
            limitTags={limitTags}
            options={items}
            value={selectedOptions}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            noOptionsText={noOptionsText}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)
                filterRef.current = filtered
                return [
                    { label: selectAllLabel, value: 'select-all' },
                    ...filtered,
                ]
            }}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
        />
    )
}

MultiSelect.defaultProps = {
    limitTags: 2,
    items: [],
    selectedValues: [],
    getOptionLabel: (value) => value,
}

export default MultiSelect
