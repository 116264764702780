import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import {
    parsedFuelAndStatus,
    tripTimeFromDaySummary,
    getTimeDiff,
} from '../../utils/TimeUtils'
import { parseDaySummary } from '../../utils/MapUtils.js'

import {
    daySummarySuccess,
    daySummaryFailure,
} from '../actions/daySummaryAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'

const SQR_MTS_ACRES = 0.000247105

function* asyncDaySummary(action) {
    let area = '0',
        data = '0',
        totalTime = '0',
        kms = '0',
        status = '0',
        fuelLevel = '0',
        lastActive = '0',
        dailyStatus,
        commutes,
        patches,
        patchesLatsCentroid,
        patchesLongsCentroid,
        patchesArea,
        replay,
        patchesStartTime,
        patchesEndTime

    lastActive = action.payload.realTime
        ? getTimeDiff(action.payload.realTime.time_stamp)
        : '0'
    try {
        yield put(clearErrors())
        const parsedData = parsedFuelAndStatus(
            action.payload.realTime,
            action.payload.tractor
        )
        status = parsedData[0]
        fuelLevel = parsedData[1]
        const res = yield call(_api.fetchDaySummary, {
            deviceId: action.payload.tractor.device_pk,
            date: action.payload.date,
            tractor: action.payload.tractor,
            realTime: action.payload.realTime,
        })

        if (res && res.status) {
            let commutePatchesLength = 0
            for (let key of Object.keys(res.data.commutes)) {
                commutePatchesLength =
                    commutePatchesLength + res.data.commutes[key].length
            }
            if (commutePatchesLength > 0) {
                dailyStatus = res.status
                data = parseDaySummary(res)
                area = (data.area * SQR_MTS_ACRES).toFixed(2)
                totalTime = tripTimeFromDaySummary(
                    data.haulage_time + data.idling_time + data.job_time
                )
                kms = (data.distance / 1000).toFixed(2)
                const res2 = yield call(_api.fetchDayDetail, {
                    deviceId: data.device_fk_id,
                    date: action.payload.date,
                })

                commutes = res2.commutes
                patches = res2.patches
                replay = res2.replay
            }
            patchesLatsCentroid = res.data['patches']['lat_centroid']
            patchesLongsCentroid = res.data['patches']['lon_centroid']
            patchesArea = res.data['patches']['patch_area']
            patchesStartTime = res.data['patches']['patch_start_ts']
            patchesEndTime = res.data['patches']['patch_end_ts']
        }
        yield put(
            daySummarySuccess({
                status,
                dailyStatus,
                area,
                totalTime,
                kms,
                fuelLevel,
                lastActive,
                commutes,
                patches,
                replay,
                patchesLatsCentroid,
                patchesLongsCentroid,
                patchesArea,
                patchesStartTime,
                patchesEndTime,
            })
        )
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(daySummaryFailure(e))
    }
}

export default function* daySummaryRequestSaga() {
    yield takeLatest(types.DAY_SUMMARY_REQUEST, asyncDaySummary)
}
