import { takeLatest, call, put } from 'redux-saga/effects'
import {
    tractorModelSuccess,
    tractorModelFailure,
} from '../actions/tractorModelAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncTractorModelSaga(action) {
    try {
        yield put(clearErrors())
        let resTractorModel = yield call(_api.tractorModelAPI, {
            requestTractorModelId: action.payload.requestTractorModelId,
        })
        yield put(tractorModelSuccess({ resTractorModel }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(tractorModelFailure({ error: e }))
    }
}
export default function* tractorModelSaga() {
    yield takeLatest(types.TRACTOR_MODEL_REQUEST, asyncTractorModelSaga)
}
