import * as types from '../types'

const initialState = {
    loading: false,
    requestTractorModelId: null,
    resTractorModel: null,
    error: null,
}

export default function tractorModelReducer(state = initialState, action) {
    switch (action.type) {
        case types.TRACTOR_MODEL_REQUEST:
            return {
                ...state,
                loading: true,
                requestTractorModelId: action.payload.requestTractorModelId,
            }

        case types.TRACTOR_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                resTractorModel: action.payload.resTractorModel,
            }

        case types.TRACTOR_MODEL_FAILURE:
            return {
                ...state,
                loading: false,
                requestTractorId: null,
                resTractorModel: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}
