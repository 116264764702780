import { all } from 'redux-saga/effects'
import otpRequestSaga from './otpRequestSaga'
import loginRequestSaga, {
    logoutRequestSaga,
    loginUpdateSaga,
} from './loginSaga'
import daySummaryRequestSaga from './daySummarySaga'
import tractorRequestSaga from './tractorSaga'
import filterTractorSaga from './filterTractorSaga'
import fetchNotificationSaga from './fetchNotificationSaga'
import getGromaxRequestSaga from './getGromaxSaga'
import updateSaga from './updateSaga'
import realtimeSaga from './realtimeSaga'
import tractorDetailUpdateSaga from './tractorDetailSaga'
import tractorCompanySaga from './tractorCompanySaga'
import tractorModelSaga from './tractorModelSaga'
import getAllGeofenceSaga from './getAllGeofenceSaga'
import editGeofenceSaga from './editGeofenceSaga'
import getTodayStatusSaga from './getTodayStatusSaga'
import getDayEventsSaga from './getDayEventsSaga'

export default function* rootSaga() {
    yield all([
        loginRequestSaga(),
        logoutRequestSaga(),
        loginUpdateSaga(),
        otpRequestSaga(),
        filterTractorSaga(),
        daySummaryRequestSaga(),
        tractorRequestSaga(),
        fetchNotificationSaga(),
        getGromaxRequestSaga(),
        updateSaga(),
        realtimeSaga(),
        tractorDetailUpdateSaga(),
        tractorCompanySaga(),
        tractorModelSaga(),
        getAllGeofenceSaga(),
        editGeofenceSaga(),
        getTodayStatusSaga(),
        getDayEventsSaga(),
    ])
}
