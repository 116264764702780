import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    nortification: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100vh',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
    },
    nortificationHeader: {
        width: '420px',
        height: '60px',
        background: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#FFFFFF',
        position: 'fixed',

        padding: '0 1.3em',
        '& > p': {
            cursor: 'pointer',
        },
    },
    cross: {
        cursor: 'pointer',
    },
    card: {
        height: '30px',
        margin: '30px',
    },
}))

function List({
    setNortificationOpen,
    Close,
    setSelected,
    nortificationOpen,
    notificationValue,
    tractorName,
    ...props
}) {
    const classes = useStyles()
    const [notification, setNotification] = useState([])

    useEffect(() => {
        const allAlerts = props.notify?.flatMap((obj) => Object.values(obj)[0])
        let data = allAlerts?.reduce((acc, val, index) => {
            if (notificationValue === val.fleet_category) {
                acc = [...acc, val]
            }
            return acc
        }, [])
        setNotification(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.notify])

    const getName = (id) => {
        let filteredObject = props.allTractors?.find(
            (element) => element.device_pk === id
        )
        if (props.localTracName) {
            for (let i = 0; i < props.localTracName.length; i++) {
                if (props.localTracName[i].id === filteredObject.id) {
                    return props.localTracName[i].name.toUpperCase()
                }
            }
            return filteredObject?.['name']
        } else {
            return filteredObject?.['name']
        }
    }

    return (
        <>
            <div className={classes.nortificationHeader}>
                <p>Alerts</p>
                <p
                    className={classes.cross}
                    onClick={() => {
                        setNortificationOpen(!nortificationOpen)
                        setSelected(false)
                    }}
                >
                    <img src={Close} alt="close" /> Close
                </p>
            </div>
            <div style={{ scrollPaddingTop: '80px', margin: '80px auto' }}>
                <p
                    onClick={() => setSelected(false)}
                    style={{ cursor: 'pointer', padding: '0.5em 1em' }}
                >
                    Back to Category
                </p>
                {notification?.map((item) => {
                    return (
                        <Card
                            style={{
                                cursor: 'pointer',
                                margin: '10px',
                                minHeight: '100px',
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'space-around',
                                border: 'groove',
                                boxShadow: '3px 4px #888888',
                                flexDirection: 'column',
                                padding: '10px',
                                marginBottom: '20px',
                            }}
                        >
                            <div>{item?.message}</div>
                            <div>{getName(item?.device_id)}</div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '80%',
                                }}
                            >
                                <div> {item?.time_stamp.split(' ')[1]}</div>
                                <div>
                                    {' '}
                                    {item?.time_stamp
                                        .split(' ')[0]
                                        .split('-')
                                        .reverse()
                                        .join('-')}
                                </div>
                            </div>
                        </Card>
                    )
                })}
                {notification?.length === 0 && (
                    <p style={{ marginLeft: '20px' }}>
                        THERE ARE NO MESSAGES AVAILABLE CURRENTLY
                    </p>
                )}
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    notify: state.fetchNotificationReducer.data,
    allTractors: state.loginReducer.allTractors,
    localTracName: state.localTractorNameReducer.localTractorNameList,
})

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(List)
