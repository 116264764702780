import React from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import Loader from "../assets/loader.svg";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 1s",

    "& > img": {
      width: "70px",
    },
  },
}));

const InfiniteLoader = () => {
  const classes = useStyles();

  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classes.wrapper}
    >
      <img src={Loader} alt="loading..." />
    </motion.div>
  );
};

export default withTheme(InfiniteLoader);
