import * as types from '../types'

export const getDayEventsRequest = ({ requestDeviceID }) => {
    return {
        type: types.GET_DAY_EVENTS_REQUEST,
        payload: { requestDeviceID },
    }
}

export const getDayEventsSuccess = ({ resAllEvents }) => {
    return {
        type: types.GET_DAY_EVENTS_SUCCESS,
        payload: {
            resAllEvents,
        },
    }
}

export const getDayEventsFailure = (error) => {
    return {
        type: types.GET_DAY_EVENTS_FAILURE,
        payload: {},
        error: error,
    }
}

export const getDayEventsReset = () => {
    return {
        type: types.GET_DAY_EVENTS_RESET,
    }
}
