import * as types from '../types'

const initialState = {
    loading: false,
    requestTractorId: null,
    resAllGeofence: null,
    error: null,
}

export default function getAllGeofenceReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_GEOFENCE_REQUEST:
            return {
                ...state,
                loading: true,
                requestTractorId: action.payload.requestTractorId,
            }

        case types.GET_ALL_GEOFENCE_SUCCESS:
            return {
                ...state,
                loading: false,
                resAllGeofence: action.payload.resAllGeofence,
            }

        case types.GET_ALL_GEOFENCE_FAILURE:
            return {
                ...state,
                loading: false,
                requestTractorId: null,
                resAllGeofence: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}
