import React from 'react'
import { makeStyles, withTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inputBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "#FFFFFF",
        borderRadius: "4px",
        opacity: 1,
        margin: "1em 0",

        "&:focus-within": {
            outline: "none !important",
            border: "1px solid #4d4d4d"
        }
    },
    inputBoxIcon: {
        padding: "0.5em",
        "& > img": {
            width: "22px"
        }
    },
    input: {
        flex: 1,
        outline: "none",
        border: "none",
        background: "none",
        fontSize: "1em",
        color: "#333333",
        height: "100%"
    },
    error: {
        fontSize: "0.7em",
        marginBottom: "1em",
        color: "red"
    },
    inputError: {
        border: "1px solid red",
    }
}));

function Input(props) {
    const classes = useStyles();
    return (
        <>
            <div
                className={`${classes.inputBox} ${props.error && classes.inputError}`}
                style={{
                    width: `${props.width ? props.width : "auto"}`,
                    height: `${props.height ? props.height : "45px"}`,
                    marginBottom: `${props.error ? "0" : "1em"}`,
                    border: `${props.border === "none" ? "none" : "1px solid #999999"}`
                }}
            >
                {props.icon && !props.iconPosition && <span className={classes.inputBoxIcon}><img src={props.icon ? props.icon : ""} alt="icon" /></span>}
                <input
                    className={classes.input}
                    type={props.type ? props.type : "text"}
                    placeholder={props.placeholder ? props.placeholder : ""}
                    value={props.value ? props.value : undefined}
                    onChange={props.onChange ? props.onChange : undefined}
                    name={props.name ? props.name : undefined}
                    spellCheck="false"
                    style={{
                        margin: `${props.iconPosition === "right" ? "0 0 0 1em" : "0 1em 0 0"}`
                    }}
                />

                {props.icon && props.iconPosition === "right" && <span className={classes.inputBoxIcon}><img src={props.icon ? props.icon : ""} alt="icon" /></span>}
            </div>
            {props.error && <p className={classes.error}>*{props.error}</p>}
        </>
    )
}

export default withTheme(Input);
