import DaySummary from "../models/DaySummary";
import Patches from "../models/Patches";
import Commutes from "../models/Commutes";
import PointsOnMap from "../models/PointsOnMap";
// import { isComputedPropertyName } from "typescript";
// import { isInteger } from "formik";
// import Replay from '../models/'

const MAGIC_STRING =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*_+|:<>?-=.,/";
const BASE = 40;
const STATUS_MAGIC_STRING =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*_+|:<>?-=.,/";
export const parseDaySummary = (daySummary) => {
  const { data } = daySummary;
  let commutes = [];
  let patches = [];
  let com = data.commutes;
  let pat = data.patches;
  for (let i = 0; i < com.id.length; i++) {
    let commute = new Commutes(
      com.start[i],
      com.p20[i],
      com.p40[i],
      com.p60[i],
      com.p80[i],
      com.end[i],
      com.id[i]
    );
    commutes.push(commute);
  }

  for (let i = 0; i < pat.id.length; i++) {
    let patch = new Patches(
      pat.running_time[i],
      pat.patch_area[i],
      pat.lat_centroid[i],
      pat.lon_centroid[i],
      pat.id[i],
      pat.fuel_consumed[i],
      pat.boundary_radius_avg[i],
      pat.patch_tags[i]
    );
    patches.push(patch);
  }

  let finalDaySummary = new DaySummary(
    data.device_fk_id + data.date,
    data.date,
    data.computation_id,
    data.idling_time,
    data.job_time,
    data.haulage_time,
    data.distance,
    data.total_fuel,
    data.total_fuel_lit,
    data.fuel_params.max,
    data.fuel_params.min,
    data.area,
    data.device_fk_id,
    data.details_flag,
    patches,
    commutes
  );

  return finalDaySummary;
};

export const parseDayDetails = (data) => {
  const commutesParsed = addArraysToDecompressedData(data.commutes);
  const patchesParsed = addArraysToDecompressedData(data.patches);
  const replayParsed = addArraysToDecompressedReplay(data.replay);
  data["commutes"] = formPointsFromCommutesOrPatches(commutesParsed);
  data["patches"] = formPointsFromCommutesOrPatches(patchesParsed);
  data["replay"] = formPointsFromReplay(replayParsed);
  return data;
};

// export const parseDayDetails = (data) => {
//   const commutesParsed = addArraysToDecompressedData(data.commutes);
//   data["commutes"] = formPointsFromCommutesOrPatches(commutesParsed);
//   const patchesParsed = addArraysToDecompressedData(data.patches);
//   data["patches"] = formPointsFromCommutesOrPatches(patchesParsed);
//   let replayParsed;
//   // replayParsed = addArraysToDecompressedReplay(data.replay);
//   if (commutesParsed.id.length > 0 && patchesParsed.id.length > 0) {
//     replayParsed = addArraysToDecompressedReplay(data.replay);
//     data["replay"] = formPointsFromReplay(replayParsed);
//   }
//   // data["replay"] = formPointsFromReplay(replayParsed);
//   // data["replay"] = formPointsFromReplay(replayParsed);
//   return data;
// };

function formPointsFromReplay(replay) {
  const lats = replay.lats;
  const lngs = replay.lons;

  const lat = [];
  const lon = [];
  const id = [];
  const ts = replay.ts;
  const status = replay.status;
  // const speed = replay.speed;
  for (let i = 0; i < lats.length; i++) {
    lat.push(lats[i]);
    lon.push(lngs[i]);
    id.push(id[i]);
    ts.push(ts[i]);
    status.push(status[i]);
  }
  const points = new PointsOnMap();
  points.lats = lat;
  points.lngs = lon;
  points.ids = id;
  points.ts = ts;
  points.status = status;
  // points.speed = speed;
  return points;
}
function formPointsFromCommutesOrPatches(commutesOrPatches) {
  const lats = commutesOrPatches.lat;
  const lngs = commutesOrPatches.lon;
  const ids = commutesOrPatches.id;
  const lat = [];
  const lon = [];
  const id = [];

  for (let i = 0; i < lats.length; i++) {
    lat.push(lats[i]);
    lon.push(lngs[i]);
    id.push(ids[i]);
  }

  const points = new PointsOnMap();
  points.lats = lat;
  points.lngs = lon;
  points.ids = id;

  return points;
}

function addArraysToDecompressedReplay(replay) {
  let ref_lat = [replay.ref_lat];
  let ref_long = [replay.ref_lon];
  const commute_lat_array = getFinalReplayArray(replay.lats, ref_lat);
  const commute_lon_array = getFinalReplayArray(replay.lons, ref_long);
  const ts = getCompleteTripTimeStamp(replay.ts, replay.ref_ts);
  const status = getTractorIgnitionStatus(replay.status);
  // const speed=getCompleteSpeedStamp('AHAAAEMMJGHJHIIGIGIIHIHHGHHGIIFIKGFAAGEHAFHHGGIGFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAEHAIAAAAAAAAAAGGIJJAIAJILAAAIIHHIIHGGHHGHGHEHGIHHIHHHHHHGIGHGAAIGIHGFFHGHGHHHGGGIHHAFGFGHIFFHHEEHFGHGFGGGIGGGHHHGFHHEGFHFHHGHGHHHIHIAGEAAHFHGGFIHAHFIEHGFHGFIHHHHHHHHGHGHHHJKFJIIFIHIHGEIHGIHHJGIIHHHJHHIJHHIIFFIGHIFHFHFIIIHHHIGIHIHHGIAFJFHHIHIHGHHHHGGHIHGFAAAAHGGFHFGFFHFGGGHFGHGHHGFEHGGGHHFEIEGHHIGGFIEFEGEFGFFGFAFFAAGEDIIIJJIIHHHHJJGHIGJHJGEGIHHIHHFHGIIGGFGHIGHGHEIFHHJFFFFGGGHFGIIEIAAAAAAAALGIJIGGIJHHHHFHIGHIIIHHHIHHIAAAAAAGHFHGGHIHHEHGGFFDIHIJGIIIHHHFIJFHIIIIHHGHGIIAAAHHFHHFGHGEHGFAIAFGHHAEEAAAGFAEADAEFGAFDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAARPLREHJT!#@YX@XAAAAAAAAQZZFPPUSOJQJAGOTLX~WSUWZYXZXYSXMPUVWJRV~!WSUIJRPNFU@~YYIHEUOGNUYSUJQVHNVY~QAAQQANQP#~POTYZ@##WMSQAAAAFHAGAAADAAAFGIN%^^^&&#^%SATM#Y#!G~Y%^EHMYOQ^%%%JLLMLTKQ');
  replay["lats"] = commute_lat_array;
  replay["lons"] = commute_lon_array;
  replay["ts"] = ts;
  replay["status"] = status;
  // replay["speed"]=speed;
  delete replay["ref_lat"];
  return replay;
}

function addArraysToDecompressedData(commuteOrPatch) {
  let refArray = commuteOrPatch.ref;
  let ref_lat = refArray[0];
  let ref_lon = refArray[1];
  const commute_lat_array = getFinalArray(commuteOrPatch.lat, ref_lat);
  const commute_lon_array = getFinalArray(commuteOrPatch.lon, ref_lon);
  commuteOrPatch["lat"] = commute_lat_array;
  commuteOrPatch["lon"] = commute_lon_array;
  delete commuteOrPatch["ref"];
  return commuteOrPatch;
}
function getCompleteTripTimeStamp(ts, ref_time) {
  let output_array = getIntegerArray(ts, 36);
  let finalData = [];
  finalData.push(convertNotificationTimeStampToMillis(ref_time));
  for (let i = 1; i < output_array.length; i++) {
    finalData.push(finalData[i - 1] + output_array[i] * 1000);
  }
  return finalData;
}

// function getCompleteSpeedStamp(speed) {
//   let output_array = getIntegerArray(speed, 36);
//   return output_array;
// }

const convertNotificationTimeStampToMillis = (timeStamp) =>
  new Date(timeStamp + "Z").getTime();

function getFinalReplayArray(inputStr, refArr) {
  let outputArray = getIntegerArray(inputStr, 36);
  let diffOut = getBaseValueDiff(outputArray);
  let updateDoubleData = getDifferenceData(diffOut);
  let finalData = [];
  finalData.push(refArr);
  for (let i = 1; i < updateDoubleData.length; i++) {
    finalData.push(
      parseFloat(finalData[i - 1]) + parseFloat(updateDoubleData[i])
    );
  }
  return finalData;
}
function getTractorIgnitionStatus(status) {
  let count = [];
  let holder = [];
  let currentStatus = parseInt(status[0].toString());
  for (let i = 1; i < status.length; i++) {
    if (isDigit(status[i])) {
      updateStatusArray(getIntValue(holder), currentStatus, count);
      holder = [];
      currentStatus = parseInt(status[i].toString());
    } else {
      let x = STATUS_MAGIC_STRING.indexOf(status[i]);
      if (x < BASE) {
        holder.push(x);
      } else {
        holder.push(x - BASE);
      }
      if (i === status.length - 1) {
        updateStatusArray(getIntValue(holder), currentStatus, count);
      }
    }
  }
  return count;
}
const isDigit = (c) => "1234567890".includes(c);

function updateStatusArray(intValue, currentStatus, count) {
  for (let i = 0; i < intValue; i++) {
    count.push(currentStatus);
  }
}

function getIntegerArray(inputString, BASE) {
  let position = [];
  let x;
  let holder = [];
  for (let i = 0; i < inputString.length; i++) {
    x = MAGIC_STRING.indexOf(inputString.charAt(i));
    if (x < BASE) {
      holder.push(x);
    } else {
      holder.push(x - BASE);
      position.push(getIntValue(holder));
      holder = [];
    }
  }
  return position;
}
function getBaseValueDiff(originalArray) {
  let result = [];
  if (originalArray.length > 1) {
    for (let i = 0; i < originalArray.length; i++) {
      result.push(originalArray[i] - originalArray[0]);
    }
    return result;
  } else {
    return originalArray;
  }
}
function getDifferenceData(dataList) {
  let updated = [];
  for (let i = 0; i < dataList.length; i++) {
    let val = dataList[i] / 100000;
    updated.push(val);
  }
  return updated;
}
function getIntValue(list) {
  let resultSum = 0;
  list.reverse();
  for (let i = 0; i < list.length; i++) {
    resultSum = resultSum + Math.pow(36, i) * list[i];
  }
  return resultSum;
}

function getDifferenceToNextNumber(originalArray) {
  const result = [];
  if (originalArray.length > 1) {
    for (let i = 1; i <= originalArray.length - 1; i++) {
      result.push(originalArray[i] - originalArray[i - 1]);
    }
    return result;
  } else {
    return originalArray;
  }
}
function getFinalArray(inputStr, refArr) {
  const opArray = [];

  const subStrArray = getSubStrings(inputStr);

  for (let i = 0; i < subStrArray.length; i++) {
    let subStr = subStrArray[i];
    let tempArray = getDiffArray(subStr, refArr[i]);
    opArray.push(tempArray);
  }
  return opArray;
}

function getDiffArray(subStr, refArr) {
  const pos_arr = [];
  const end_ind_arr = [];
  const diffArr = [];

  for (let i = 0; i < subStr.length; i++) {
    pos_arr.push(MAGIC_STRING.indexOf(subStr.charAt(i)));
  }

  for (let i = 0; i < pos_arr.length; i++) {
    if (pos_arr[i] < BASE) {
      end_ind_arr.push(i);
    }
  }

  end_ind_arr.unshift(-1);

  let length_arr = getDifferenceToNextNumber(end_ind_arr);
  let start_ind = 0;
  const base_arr = [];

  for (let i = 0; i < length_arr.length; i++) {
    let end_ind = start_ind + length_arr[i];

    let temp = pos_arr.slice(start_ind, end_ind);

    for (let j = 0; j < temp.length - 1; j++) {
      temp[j] = temp[j] - BASE;
    }
    base_arr.push(temp);
    start_ind = end_ind;
  }

  for (let i = 0; i < base_arr.length; i++) {
    diffArr.push(0);
  }

  let sum = 0;

  for (let i = 0; i < base_arr.length; i++) {
    let tempArr = base_arr[i];
    sum = 0;
    for (let j = 0; j < tempArr.length; j++) {
      sum += tempArr[j] * Math.pow(BASE, tempArr.length - j - 1);
    }
    diffArr[i] = sum;
  }

  let shift = diffArr[0];
  for (let i = 0; i < diffArr.length; i++) {
    diffArr[i] = diffArr[i] - shift;
  }

  const outArray = [];
  for (let i = 0; i < diffArr.length + 1; i++) {
    if (i === 0) {
      outArray.push(Math.trunc(refArr * 1000000));
    } else {
      outArray.push(0);
    }
  }

  for (let i = 1; i < diffArr.length; i++) {
    outArray[i] = Math.trunc(outArray[i - 1] + diffArr[i]);
  }

  outArray.pop();
  const opDoubleArray = [];
  for (let i = 0; i < outArray.length; i++) {
    let a = outArray[i] / 1000000;
    opDoubleArray.push(a);
  }
  return opDoubleArray;
}

function getSubStrings(inputStr) {
  const positions = [];
  let endIndexArr = [];
  const subStrArray = [];
  let x;

  for (let i = 0; i < inputStr.length; i++) {
    x = inputStr.charAt(i);

    if (x === MAGIC_STRING.charAt(2 * BASE + 1)) {
      positions.push(i);
    }
  }

  positions.unshift(-1);
  endIndexArr = positions;
  let start_ind, end_ind;
  let sub_str;

  for (let i = 0; i < endIndexArr.length - 1; i++) {
    start_ind = endIndexArr[i] + 1;
    end_ind = endIndexArr[i + 1];
    sub_str = inputStr.substring(start_ind, end_ind);
    subStrArray.push(sub_str);
  }

  return subStrArray;
}
