import * as types from '../types'

const initialState = {
    loading: false,
    requestTractorDetail: null,
    tractorDataUpdated: null,
    error: null,
}

export default function tractorDetailReducer(state = initialState, action) {
    switch (action.type) {
        case types.TRACTOR_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                requestTractorDetail: action.payload.requestTractorDetail,
            }

        case types.TRACTOR_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                tractorDataUpdated: action.payload.tractorDataUpdated,
            }

        case types.TRACTOR_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                requestTractorDetail: null,
                tractorDataUpdated: null,
                error: action.payload.error,
            }

        case types.TRACTOR_DETAIL_RESET:
            return {
                ...state,
                loading: false,
                requestTractorDetail: null,
                tractorDataUpdated: null,
                error: null,
            }

        default:
            return state
    }
}
