import { put, takeLatest, call } from 'redux-saga/effects'
import * as types from '../types'
import { returnErrors, clearErrors } from '../actions/errorAction'
import {
    fetchNotificationFailure,
    fetchNotificationSuccess,
} from '../actions/fetchNotificationAction'
import * as _api from '../../api'

function* asyncFetchNotificationSaga(action) {
    try {
        yield put(clearErrors())
        const res = yield call(_api.fetchNotification, {
            id: action.payload.id,
        })
        if (res.status) {
            yield put(
                fetchNotificationSuccess({
                    data: res.data,
                    status: res.status,
                })
            )
        } else if (!res.status) {
            yield put(fetchNotificationFailure({ message: res.message }))
        }
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(fetchNotificationFailure(e))
    }
}
export default function* fetchNotificationSaga() {
    yield put(clearErrors())
    yield takeLatest(
        types.FETCH_NOTIFICATION_REQUEST,
        asyncFetchNotificationSaga
    )
}
