import { combineReducers } from 'redux'
import otpRequestReducer from './otpRequestReducer'
import loginReducer from './loginReducer'
import daySummaryReducer from './daySummaryReducer'
import tractorReducer from './tractorReducer'
import errorReducer from './errorReducer'
import fetchNotificationReducer from './fetchNotificationReducer'
import getGromaxReducer from './getGromaxReducer'
import selectDateReducer from './selectDateReducer'
import realtimeReducer from './realtimeReducer'
import tractorDetailReducer from './tractorDetailReducer'
import localTractorNameReducer from './localTractorNameReducer'
import tractorCompanyReducer from './tractorCompanyReducer'
import tractorModelReducer from './tractorModelReducer'
import getAllGeofenceReducer from './getAllGeofenceReducer'
import editGeofenceReducer from './editGeofenceReducer'
import getTodayStatusReducer from './getTodayStatusReducer'
import getDayEventsReducer from './getDayEventsReducer'

const rootReducer = combineReducers({
    loginReducer,
    otpRequestReducer,
    daySummaryReducer,
    tractorReducer,
    errorReducer,
    realtimeReducer,
    fetchNotificationReducer,
    gromaxReducer: getGromaxReducer,
    dateReducer: selectDateReducer,
    tractorUpdateReducer: tractorDetailReducer,
    localTractorNameReducer,
    tractorCompanyReducer,
    tractorModelReducer,
    getAllGeofenceReducer,
    editGeofenceReducer,
    getTodayStatusReducer,
    getDayEventsReducer,
})

export default rootReducer
