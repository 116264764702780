import React, { Suspense, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Filter from '../assets/home/Filter.svg'
import Arrow from '../assets/login/Long-arrow.svg'
import TodayTractor from '../assets/todayTractor.svg'
import TodayHour from '../assets/todayTime.svg'
import AreaIcon from '../assets/area_icon.svg'
import TodayDistance from '../assets/todayDistance.svg'
import Skeleton from '@material-ui/lab/Skeleton'
import Tractor from './tractor'
import { AnimateSharedLayout, motion } from 'framer-motion'
import Tractordesc from './TractorDesc'
import { Button, InfiniteLoader } from '../elements'
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import {
    filterTractorRequest,
    resetAllFilters,
} from '../store/actions/filterTractorAction'
import Modal from '@material-ui/core/Modal'
import Close from '../assets/home-tractor/close.svg'
import Chip from '@material-ui/core/Chip'
import { realtimeRequest } from '../store/actions/realtimeRequestAction'
import { getTodayStatusRequest } from '../store/actions/getTodayStatusAction'
import { tripTimeFromDaySummary, getTimeStatusDiff } from '../utils/TimeUtils'
import MultiSelect from '../elements/multiSelect'
import { tractorRequest } from '../store/actions/tractorAction'
import { daySummaryRequest } from '../store/actions/daySummaryAction'
import { useLocation, useParams } from 'react-router-dom'

const GoogleMap = React.lazy(() => import('./GoogleMap/GoogleMap'))

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    statusSidebar: {
        overflowY: 'scroll',
        height: '150px',
        background: theme.palette.secondary.main,
        padding: '1em',
        marginTop: '1em',
        position: 'absolute',
        right: '0',
        borderRadius: '8px 0px 0px 8px',
    },
    todayStatusHeader: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textTransform: 'capitalize',
        color: '#333333',
        paddingBottom: '10px',
    },
    todayStatusWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '9px',
    },
    todayStatusItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: 1,
        justifyContent: 'flex-start',
        gap: '5px',
        margin: '10px 0px 0px 0px',
    },
    todayStatusItemValue: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '27px',
        textTransform: 'uppercase',
        color: '#000',
    },
    todayStatusItemTitle: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#666',
    },
    sidebar: {
        overflowY: 'scroll',
        height: 'calc(95vh - 220px)',
        background: theme.palette.secondary.main,
        padding: '0 1em',
        //To make Zoom Visible Remove below code
        marginTop: '13.5em',
        position: 'absolute',
        right: '0',
        borderRadius: '8px 0px 0px 8px',
    },
    filterOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > h2': {
            fontSize: '1.2em',
        },
        padding: '1em 0',
    },
    filterBtn: {
        display: 'flex',
        alignItems: 'center',
        outline: 'none',
        background: 'none',
        padding: '0.3em 1em',
        fontSize: '12px',
        gap: '7px',
        border: 'none',
        color: '#000',
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
    tractorListWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    googleMap: {
        alignSelf: 'flex-start',
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
        flex: 1,
        position: 'relative',
    },
    filterOptions: {
        position: 'absolute',
        width: '800px',
        height: '650px',
        top: '5%',
        left: '50%',
        transform: 'translate(-50%,0%)',
        background: '#FFFFFF',
        outline: 'none',
        border: 'none',
        borderRadius: '4px',
        padding: '2em',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    filterAutoComplete: {
        margin: '2em 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '2em',
        '& > * ': {
            flex: 1,
        },
    },
    filterOptionsHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > p': {
            cursor: 'pointer',
        },
    },
    sidebarEngineOff: {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        background: '#FF0C22',
        position: 'relative',
        zIndex: 4,

        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-4px',
            left: '-4px',
            bottom: '-4px',
            right: '-4px',
            background: '#BE1E2D',
            zIndex: 1,
            borderRadius: '50%',
            opacity: '0.3',
        },
    },
    sidebarEngineOn: {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        background: '#00c441',
        position: 'relative',
        zIndex: 4,

        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-4px',
            left: '-4px',
            bottom: '-4px',
            right: '-4px',
            background: '#007c29',
            zIndex: 1,
            borderRadius: '50%',
            opacity: '0.3',
        },
    },
}))

const variants = {
    open: { width: '400px' },
    closed: { width: '125px' },
}

function Sidebar({
    tractors,
    realtimeList,
    show,
    setShow,
    selectedTractor,
    setSelectedTractor,
    position,
    setposition,
    showTrips,
    setshowTrips,
    onSelectedTractorChanged,
    onShowTrips,
    showTracking,
    setShowTracking,
    ...props
}) {
    const classes = useStyles()
    const [tractorModels, setTractorModels] = useState(null)
    const [tractorMakers, setTractorMakers] = useState(null)
    const [tractorName, setTractorName] = useState(null)
    const [drawingTool, setDrawingTool] = useState()
    const [showAreaButton, setShowAreaButton] = useState(false)
    const [shapes, setShapes] = useState()

    const [addGeofence, setAddGeofence] = useState(false)
    const [currentGeofence, setCurrentGeofence] = useState(null)

    const [newGeofence, setNewGeofence] = useState(false)
    const [nameValue, setNameValue] = useState([])
    const [tableData, setTableData] = useState([])
    const [sortedTractors, setSortedTractors] = useState([])

    const location = useLocation()
    const { id, date } = useParams()

    const [recentFilter, setRecentFilter] = useState([
        {
            id: '',
            maker: [],
            model: [],
            name: [],
        },
    ])

    let updateRealtimeTractorRef = useRef(null)
    let intervalTimeRef = useRef(0)
    let todayStatusRef = useRef(0)

    const getName = (tractor) => {
        const updatedName = props.localTracName?.find(
            (item) => item.id === tractor.id
        )
        if (updatedName) {
            return updatedName.name
        } else {
            return tractor.name
        }
    }

    useEffect(() => {
        if (location.pathname.startsWith('/tracking')) {
            setShowTracking(true)
            if (realtimeList && tractors) {
                let tractor, realTime
                tractor = tractors.find((tr) => tr.device_pk === parseInt(id))
                if (tractor) {
                    realTime = realtimeList.find(
                        (rt) => rt.fb_key === tractor.fb_key
                    )
                }
                props.selectTractor({ tractor, realTime })
                props.daySummary({ tractor, realTime, date: date })
                setShow(false)
                onShowTrips(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, realtimeList, tractors])

    useEffect(() => {
        setTractorModels([
            ...new Set(props.allTractors.map((tractor) => tractor.model)),
        ])
        setTractorMakers([
            ...new Set(props.allTractors.map((tractor) => tractor.make)),
        ])
        let nameData = []
        for (let i = 0; i < props.allTractors.length; i++) {
            let checkName = getName(props.allTractors[i])
            nameData.push({
                label: checkName,
                value: props.allTractors[i]['name'],
            })
        }
        setTractorName(nameData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allTractors])

    useEffect(() => {
        if (props.localTracName) {
            let nameData = []
            for (let i = 0; i < props.allTractors.length; i++) {
                let checkName = getName(props.allTractors[i])
                nameData.push({
                    label: checkName,
                    value: props.allTractors[i]['name'],
                })
            }
            setTractorName(nameData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.localTracName])

    const [filterOpen, setFilterOpen] = useState(false)
    const [filterModel, setFilterModel] = useState('')
    const [filterMake, setFilterMake] = useState('')

    const onFilterSubmit = () => {
        let list = recentFilter
        let nameValueData = []
        for (let i = 0; i < nameValue.length; i++) {
            nameValueData.push(nameValue[i]['value'])
        }
        const data = JSON.parse(localStorage.getItem('recentFilterData'))
        if (data) {
            list = data
        }
        list = [
            ...list,
            {
                id: props.userID,
                maker: filterMake,
                model: filterModel,
                name: nameValueData,
            },
        ]
        setRecentFilter(list)
        localStorage.setItem('recentFilterData', JSON.stringify(list))
        props.filterTractors({
            models: filterModel,
            makers: filterMake,
            names: nameValueData,
        })
        recentSearches()
        setFilterOpen(!filterOpen)
    }

    useEffect(() => {
        setFilterOpen(filterOpen && show)
    }, [show, filterOpen])

    useEffect(() => {
        if (tractors) {
            let requestUserID
            if (localStorage.getItem('user_type') === 'dealer') {
                requestUserID = {
                    dealer_id: props.userID,
                }
            } else {
                requestUserID = {
                    user_id: props.userID,
                }
            }

            props.getOverAllStats({ requestUserID })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tractors])

    useEffect(() => {
        if (tractors) {
            if (todayStatusRef.current) {
                clearInterval(todayStatusRef.current)
            }
            let requestUserID
            if (localStorage.getItem('user_type') === 'dealer') {
                requestUserID = {
                    dealer_id: props.userID,
                }
            } else {
                requestUserID = {
                    user_id: props.userID,
                }
            }
            todayStatusRef.current = setInterval(() => {
                props.getOverAllStats({ requestUserID })
            }, 30000)
        }
        return () => {
            if (todayStatusRef.current) {
                clearInterval(todayStatusRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tractors])

    useEffect(() => {
        if (tractors) {
            if (tractors.length > 0 && tractors.length < 30) {
                intervalTimeRef.current = 30000
            } else if (tractors.length > 30 && tractors.length < 100) {
                intervalTimeRef.current = 60000
            } else if (tractors.length > 100 && tractors.length < 500) {
                intervalTimeRef.current = 180000
            } else if (tractors.length > 500 && tractors.length < 1000) {
                intervalTimeRef.current = 300000
            } else if (tractors.length > 1000) {
                intervalTimeRef.current = 600000
            }
            props.selectRealtimeAPI({
                phone: localStorage.getItem('phoneNumber'),
            })
            if (intervalTimeRef.current > 0) {
                if (updateRealtimeTractorRef.current) {
                    clearInterval(updateRealtimeTractorRef.current)
                }
                updateRealtimeTractorRef.current = setInterval(() => {
                    props.selectRealtimeAPI({
                        phone: localStorage.getItem('phoneNumber'),
                    })
                }, intervalTimeRef.current)
            }
        }
        return () => {
            if (updateRealtimeTractorRef.current) {
                clearInterval(updateRealtimeTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tractors])

    useEffect(() => {
        if (!addGeofence) {
            if (intervalTimeRef.current > 0) {
                if (updateRealtimeTractorRef.current) {
                    clearInterval(updateRealtimeTractorRef.current)
                    updateRealtimeTractorRef.current = null
                }
                updateRealtimeTractorRef.current = setInterval(() => {
                    props.selectRealtimeAPI({
                        phone: localStorage.getItem('phoneNumber'),
                    })
                }, intervalTimeRef.current)
            }
        }
        return () => {
            if (updateRealtimeTractorRef.current) {
                clearInterval(updateRealtimeTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addGeofence])

    useEffect(() => {
        if (!newGeofence) {
            if (intervalTimeRef.current > 0) {
                if (updateRealtimeTractorRef.current) {
                    clearInterval(updateRealtimeTractorRef.current)
                    updateRealtimeTractorRef.current = null
                }
                updateRealtimeTractorRef.current = setInterval(() => {
                    props.selectRealtimeAPI({
                        phone: localStorage.getItem('phoneNumber'),
                    })
                }, intervalTimeRef.current)
            }
        }
        return () => {
            if (updateRealtimeTractorRef.current) {
                clearInterval(updateRealtimeTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newGeofence])

    const checkEngineText = (item) => {
        let value = getTimeStatusDiff(
            props.checkRealtime.data[`${item.device_pk}`]['time_stamp']
        )
        if (
            (props.checkRealtime.data[`${item.device_pk}`]['status'] && 1) === 1
        ) {
            if (value > 10) {
                //DIS
                return 3
            } else {
                // ON
                return 1
            }
        } else if (
            (props.checkRealtime.data[`${item.device_pk}`]['status'] && 1) === 0
        ) {
            if (value > 120) {
                // DIS
                return 3
            } else {
                // OFF
                return 2
            }
        }
    }

    useEffect(() => {
        if (props.checkRealtime?.data) {
            let tractorsEngineStatus = tractors.map((obj) => ({
                ...obj,
                engineStatus: checkEngineText(obj),
            }))
            tractorsEngineStatus.sort((a, b) => a.engineStatus - b.engineStatus)
            setSortedTractors(tractorsEngineStatus)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.checkRealtime])

    const getActiveTractors = () => {
        let activeTractorsCount = 0
        for (let i = 0; i < tractors.length; i++) {
            if (
                tripTimeFromDaySummary(
                    props.checkRealtime.data?.[`${tractors[i].device_pk}`][
                        'time'
                    ]
                ) !== '00:00'
            ) {
                activeTractorsCount++
            }
        }
        return activeTractorsCount
    }

    const getActiveHours = () => {
        let activeHoursCount = 0
        for (let i = 0; i < tractors.length; i++) {
            activeHoursCount +=
                props.checkRealtime.data[`${tractors[i].device_pk}`]['time']
        }
        return tripTimeFromDaySummary(activeHoursCount)
    }

    const getActiveDistance = () => {
        let activeDistance = 0
        for (let i = 0; i < tractors.length; i++) {
            activeDistance +=
                props.checkRealtime.data[`${tractors[i].device_pk}`]['kms']
        }
        return activeDistance.toFixed(2)
    }

    const getActiveAcres = () => {
        let activeAcres = 0
        for (let i = 0; i < tractors.length; i++) {
            activeAcres +=
                props.checkRealtime.data[`${tractors[i].device_pk}`]['acres']
        }
        return activeAcres.toFixed(2)
    }

    const filterRowValue = (search) => {
        if (search === '' || !search.length) {
            return '-'
        } else {
            return search.join(', ')
        }
    }

    const recentSearches = () => {
        const data = JSON.parse(localStorage.getItem('recentFilterData'))
        let recentFilterTableData = []
        let filteredData
        if (data?.length > 1) {
            filteredData = data.filter((item) => item.id === props.userID)
            for (let i = 0; i < filteredData.length; i++) {
                recentFilterTableData.push({
                    id: filteredData[i]['id'],
                    maker: filterRowValue(filteredData[i]['maker']),
                    model: filterRowValue(filteredData[i]['model']),
                    name: filterRowValue(filteredData[i]['name']),
                })
            }
        }
        setTableData(recentFilterTableData)
    }

    const labelName = (data) => {
        let makeString = data.maker === '-' ? '' : data.maker
        let modelString = data.model === '-' ? '' : data.model
        let nameString = data.name === '-' ? '' : data.name

        let label = `${makeString} ${modelString} ${nameString}`

        let chipLabel = label
            .replace(/\bundefined\b/g, '')
            .replace(/\s+/g, ' ')
            .trim()

        if (chipLabel.length < 25) {
            return chipLabel
        } else {
            return `${chipLabel.slice(0, 25)}...`
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.googleMap}>
                <Suspense fallback={<InfiniteLoader />}>
                    <GoogleMap
                        realTimeList={realtimeList}
                        tractorList={tractors}
                        selectedPosition={position}
                        showTrips={showTrips}
                        selectedTractor={selectedTractor}
                        tractor={
                            selectedTractor ? selectedTractor.tractor : null
                        }
                        realTime={
                            selectedTractor ? selectedTractor.realTime : null
                        }
                        position={position}
                        show={show}
                        setShow={setShow}
                        onShowTrips={onShowTrips}
                        setSelectedTractor={setSelectedTractor}
                        onPositionChange={onSelectedTractorChanged}
                        drawingTool={drawingTool}
                        setDrawingTool={setDrawingTool}
                        showAreaButton={showAreaButton}
                        setShowAreaButton={setShowAreaButton}
                        shapes={shapes}
                        setShapes={setShapes}
                        addGeofence={addGeofence}
                        setAddGeofence={setAddGeofence}
                        currentGeofence={currentGeofence}
                        setCurrentGeofence={setCurrentGeofence}
                        newGeofence={newGeofence}
                        setNewGeofence={setNewGeofence}
                        showTracking={showTracking}
                        setShowTracking={setShowTracking}
                    />
                </Suspense>
            </div>
            {show && !showTracking && (
                <motion.div
                    className={classes.statusSidebar}
                    transition={{ duration: 0.2 }}
                    animate="open"
                    variants={variants}
                >
                    <span className={classes.todayStatusHeader}>
                        Today's Status
                    </span>
                    <div className={classes.todayStatusWrapper}>
                        <div className={classes.todayStatusItem}>
                            <img src={TodayTractor} alt="today-stats-tractor" />
                            {props.todayStatsLoading &&
                            props.todayStats === null ? (
                                <Skeleton animation="wave" />
                            ) : (
                                <span className={classes.todayStatusItemValue}>
                                    {props.filterNames?.length > 0 ||
                                    props.filterMakes?.length > 0 ||
                                    props.filterModels?.length > 0
                                        ? getActiveTractors()
                                        : props.todayStats &&
                                          props.todayStats.data.active_tractors}
                                </span>
                            )}
                            <span className={classes.todayStatusItemTitle}>
                                Active Today
                            </span>
                        </div>
                        <div className={classes.todayStatusItem}>
                            <img src={TodayHour} alt="today-stats-time" />
                            {props.todayStatsLoading &&
                            props.todayStats === null ? (
                                <Skeleton animation="wave" />
                            ) : (
                                <span className={classes.todayStatusItemValue}>
                                    {props.filterNames?.length > 0 ||
                                    props.filterMakes?.length > 0 ||
                                    props.filterModels?.length > 0
                                        ? getActiveHours()
                                        : props.todayStats &&
                                          tripTimeFromDaySummary(
                                              props.todayStats.data
                                                  .total_job_time_seconds
                                          )}
                                </span>
                            )}
                            <span className={classes.todayStatusItemTitle}>
                                Hrs:MM
                            </span>
                        </div>
                        <div className={classes.todayStatusItem}>
                            <img
                                src={TodayDistance}
                                alt="today-stats-distance"
                            />
                            {props.todayStatsLoading &&
                            props.todayStats === null ? (
                                <Skeleton animation="wave" />
                            ) : (
                                <span className={classes.todayStatusItemValue}>
                                    {props.filterNames?.length > 0 ||
                                    props.filterMakes?.length > 0 ||
                                    props.filterModels?.length > 0
                                        ? getActiveDistance()
                                        : (
                                              props.todayStats &&
                                              props.todayStats.data
                                                  .total_commute_distance_km
                                          )?.toFixed(2)}
                                </span>
                            )}
                            <span className={classes.todayStatusItemTitle}>
                                Kms commute
                            </span>
                        </div>
                        <div className={classes.todayStatusItem}>
                            <img
                                src={AreaIcon}
                                alt="today-total-acres"
                                width="30px"
                                height="25px"
                            />
                            {props.todayStatsLoading &&
                            props.todayStats === null ? (
                                <Skeleton animation="wave" />
                            ) : (
                                <span className={classes.todayStatusItemValue}>
                                    {props.filterNames?.length > 0 ||
                                    props.filterMakes?.length > 0 ||
                                    props.filterModels?.length > 0
                                        ? getActiveAcres()
                                        : (
                                              props.todayStats &&
                                              props.todayStats.data.total_acres
                                          )?.toFixed(2)}
                                </span>
                            )}
                            <span className={classes.todayStatusItemTitle}>
                                Total Acres
                            </span>
                        </div>
                    </div>
                </motion.div>
            )}
            {show && !showTracking && (
                <motion.div
                    className={classes.sidebar}
                    transition={{ duration: 0.2 }}
                    animate="open"
                    variants={variants}
                >
                    <motion.div
                        className={classes.filterOption}
                        style={{
                            justifyContent: 'space-between',
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        <motion.h2
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                        >
                            All Tractors - {tractors.length ?? 0}
                        </motion.h2>
                        <motion.button
                            className={classes.filterBtn}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                            onClick={() => {
                                setFilterOpen(!filterOpen)
                                recentSearches()
                            }}
                        >
                            <img src={Filter} alt="filter" />
                            Filter by
                        </motion.button>
                    </motion.div>
                    {props.filtered && (
                        <Chip
                            style={{
                                marginBottom: '1em',
                            }}
                            label=" Reset Filters"
                            onDelete={() => {
                                setFilterMake('')
                                setFilterModel('')
                                setNameValue([])
                                props.resetFilter()
                            }}
                            variant="outlined"
                        />
                    )}
                    {filterOpen && (
                        <Modal
                            open={filterOpen}
                            onClose={() => setFilterOpen(!filterOpen)}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <div className={classes.filterOptions}>
                                <div className={classes.filterOptionsHeader}>
                                    <h3>Filter By:</h3>
                                    <p
                                        onClick={() =>
                                            setFilterOpen(!filterOpen)
                                        }
                                    >
                                        <img src={Close} alt="close" /> close
                                    </p>
                                </div>
                                <div className={classes.filterAutoComplete}>
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        id="tags-maker"
                                        options={tractorMakers}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, value) =>
                                            setFilterMake(value)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Tractor Make"
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        id="tags-model"
                                        options={tractorModels}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, value) =>
                                            setFilterModel(value)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Tractor Model"
                                            />
                                        )}
                                    />
                                    <MultiSelect
                                        items={tractorName}
                                        label="Tractor Name"
                                        placeholder="Select Tractors By Name"
                                        selectAllLabel="Select all"
                                        onChange={setNameValue}
                                    />
                                </div>
                                <span
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                    }}
                                >
                                    Recent Searches
                                </span>
                                <div
                                    style={{
                                        height: '200px',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    {tableData?.map((data, index) => (
                                        <Chip
                                            style={{
                                                marginBottom: '1em',
                                                marginRight: '10px',
                                            }}
                                            key={index}
                                            label={labelName(data)}
                                            onClick={() => {
                                                props.filterTractors({
                                                    models:
                                                        data['model'] === '-'
                                                            ? ''
                                                            : data['model']
                                                                  ?.split(',')
                                                                  .map((item) =>
                                                                      item.trim()
                                                                  ),
                                                    makers:
                                                        data['maker'] === '-'
                                                            ? ''
                                                            : data['maker']
                                                                  ?.split(',')
                                                                  .map((item) =>
                                                                      item.trim()
                                                                  ),
                                                    names:
                                                        data['name'] === '-'
                                                            ? ''
                                                            : data['name']
                                                                  ?.split(',')
                                                                  .map((item) =>
                                                                      item.trim()
                                                                  ),
                                                })
                                                recentSearches()
                                                setFilterOpen(!filterOpen)
                                            }}
                                            onDelete={undefined}
                                            variant="outlined"
                                        />
                                    ))}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Button
                                        type="button"
                                        onClick={onFilterSubmit}
                                        width="100%"
                                    >
                                        <p>Filter</p>
                                        <span>
                                            <img src={Arrow} alt="arrow" />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <div className={classes.tractorListWrapper}>
                        <AnimateSharedLayout transition={{ duration: 0.3 }}>
                            {tractors?.map((tractor, index) => (
                                <Tractor
                                    key={tractor.id}
                                    index={index}
                                    position={position}
                                    realTime={
                                        realtimeList.find(
                                            (rt) =>
                                                rt.fb_key === tractors.fb_key
                                        ) ?? realtimeList[index]
                                    }
                                    tractor={tractor}
                                    show={show}
                                    setShow={setShow}
                                    onShowTrips={onShowTrips}
                                    setSelectedTractor={setSelectedTractor}
                                    onPositionChange={onSelectedTractorChanged}
                                    setShowAreaButton={setShowAreaButton}
                                    drawingTool={drawingTool}
                                    setDrawingTool={setDrawingTool}
                                    shapes={shapes}
                                    setShapes={setShapes}
                                    addGeofence={addGeofence}
                                    setAddGeofence={setAddGeofence}
                                />
                            ))}
                        </AnimateSharedLayout>
                    </div>
                    {tractors.length === 0 && (
                        <div className={classes.tractorListWrapper}>
                            <AnimateSharedLayout transition={{ duration: 0.3 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <h4>No Tractors Found</h4>
                                </div>
                            </AnimateSharedLayout>
                        </div>
                    )}
                </motion.div>
            )}
            {(!show || showTracking) && (
                <Tractordesc
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    setShow={setShow}
                    showTrips={showTrips}
                    onShowTrips={onShowTrips}
                    onPositionChange={onSelectedTractorChanged}
                    tractorList={tractors}
                    realtimeList={realtimeList}
                    drawingTool={drawingTool}
                    setDrawingTool={setDrawingTool}
                    showAreaButton={showAreaButton}
                    setShowAreaButton={setShowAreaButton}
                    shapes={shapes}
                    setShapes={setShapes}
                    addGeofence={addGeofence}
                    setAddGeofence={setAddGeofence}
                    currentGeofence={currentGeofence}
                    setCurrentGeofence={setCurrentGeofence}
                    newGeofence={newGeofence}
                    setNewGeofence={setNewGeofence}
                    showTracking={showTracking}
                    setShowTracking={setShowTracking}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    filterModels: state.loginReducer.filterModels,
    filterMakers: state.loginReducer.filterMakers,
    filterNames: state.loginReducer.filterNames,
    allTractors: state.loginReducer.allTractors,
    userID: state.loginReducer.id,
    filtered: state.loginReducer.filtered,
    checkRealtime: state.realtimeReducer.realtimeData,
    todayStatsLoading: state.getTodayStatusReducer.loading,
    todayStats: state.getTodayStatusReducer.resTodayStatus,
    dealerHandle: state.otpRequestReducer.otpSuccess,
    localTracName: state.localTractorNameReducer.localTractorNameList,
})

const mapDispatchToProps = (dispatch) => ({
    filterTractors: ({ models, makers, names }) => {
        dispatch(filterTractorRequest({ models, makers, names }))
    },
    resetFilter: () => {
        dispatch(resetAllFilters())
    },
    selectRealtimeAPI: ({ phone }) => {
        dispatch(realtimeRequest({ phone }))
    },
    getOverAllStats: ({ requestUserID }) => {
        dispatch(getTodayStatusRequest({ requestUserID }))
    },
    selectTractor: ({ tractor, realTime }) => {
        dispatch(tractorRequest({ tractor, realTime }))
    },
    daySummary: ({ realTime, tractor, date }) => {
        dispatch(daySummaryRequest({ realTime, tractor, date }))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
