import * as types from '../types'

export const filterTractorRequest = ({ models, makers, names }) => {
    return {
        type: types.FILTER_TRACTOR_REQUEST,
        payload: {
            models,
            makers,
            names,
        },
    }
}

export const filterTractorSuccess = ({ tractors, realTimes }) => {
    return {
        type: types.FILTER_TRACTOR_SUCCESS,
        payload: {
            tractors,
            realTimes,
        },
    }
}

export const filterTractorFailure = (e) => {
    return {
        type: types.FILTER_TRACTOR_FAILURE,
        payload: {},
        error: e,
    }
}

export const resetAllFilters = () => {
    return {
        type: types.RESET_ALL_FILTERS,
    }
}
