import * as types from '../types'

export const logoutRequest = () => {
    return {
        type: types.LOGOUT_REQUEST,
    }
}
export const logoutRequestSuccess = () => {
    return {
        type: types.LOGOUT_REQUEST_SUCCESS,
    }
}

export const logoutRequestFailure = (error) => {
    return {
        type: types.LOGOUT_REQUEST_FAILURE,
        error: error,
    }
}
